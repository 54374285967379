import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';

import {
  VideoTrack,
  useLocalVideo,
  useLocalAudio,
  useVideoInputDevices,
  useAudioInputDevices,
} from '@zeals/shared-components';

import Microphone from '../icon/microphone/Microphone';

import useStyles from './styles';

type DeviceSettingPanelProps = {
  selectedVideoDevice: string;
  selectedAudioDevice: string;
  onVideoDeviceChange: (deviceId: string) => void;
  onAudioDeviceChange: (deviceId: string) => void;
  isModal?: boolean;
};

const DeviceSettingPanel: React.FC<DeviceSettingPanelProps> = ({
  selectedVideoDevice,
  selectedAudioDevice,
  onVideoDeviceChange,
  onAudioDeviceChange,
  isModal = false,
}: DeviceSettingPanelProps) => {
  const classes = useStyles({ isModal });
  const { t: translate } = useTranslation();
  const videoDevices = useVideoInputDevices();
  const audioDevices = useAudioInputDevices();
  const [deviceReady, setDeviceReady] = useState(false);

  const localVideoTrack = useLocalVideo(true, {
    deviceId: selectedVideoDevice,
    name: `camera-${Date.now()}`,
    onStart: () => {
      setDeviceReady(!deviceReady);
    },
  });

  const localAudioTrack = useLocalAudio(true, {
    deviceId: selectedAudioDevice,
    name: `microphone-${Date.now()}`,
    onStart: () => {
      setDeviceReady(!deviceReady);
    },
  });

  // make default selection for video
  useEffect(() => {
    if (videoDevices.length > 0) {
      if (!selectedVideoDevice) {
        onVideoDeviceChange(videoDevices[0].deviceId);
      } else {
        // validate the saved video device exists
        const exist = videoDevices.some(
          (device) => device.deviceId === selectedVideoDevice
        );

        // reset it to first avaliable device
        if (!exist) {
          onVideoDeviceChange(videoDevices[0].deviceId);
        }
      }
    }
  }, [selectedVideoDevice, onVideoDeviceChange, videoDevices]);

  // make default selection for audio
  useEffect(() => {
    if (audioDevices.length > 0) {
      if (!selectedAudioDevice) {
        onAudioDeviceChange(audioDevices[0].deviceId);
      } else {
        // validate the saved video device exists
        const exist = audioDevices.some(
          (device) => device.deviceId === selectedAudioDevice
        );

        // reset it to first avaliable device
        if (!exist) {
          onAudioDeviceChange(audioDevices[0].deviceId);
        }
      }
    }
  }, [selectedAudioDevice, onAudioDeviceChange, audioDevices]);

  return (
    <div className={classes.panelContainerAlternative}>
      <div className="videoPreviewTitle">
        <Typography className={classes.label}>
          {translate('page.shiftManagement.content.videoSettings.previewTitle')}
        </Typography>
      </div>
      <div className="videoRow">
        <div className="videoContainer">
          {localVideoTrack ? (
            <VideoTrack className={classes.videoTag} track={localVideoTrack} />
          ) : (
            <div className="videoLoading">
              <CircularProgress />
            </div>
          )}
          <div className="micIcon">
            <Microphone localAudioTrack={localAudioTrack} />
          </div>
        </div>
        <div className="videoSettingsContainer">
          <Typography className={classes.label}>
            {translate('component.deviceSettingPanel.audioSettings')}
          </Typography>
          <FormControl
            className={classes.formControl}
            margin="dense"
            variant="outlined"
          >
            <Select
              id="audio-input"
              onChange={(e) => {
                onAudioDeviceChange(e.target.value as string);
              }}
              value={selectedAudioDevice}
            >
              {audioDevices.map((device) => {
                return (
                  <MenuItem key={device.deviceId} value={device.deviceId}>
                    {device.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <Typography className={classes.label}>
            {translate('component.deviceSettingPanel.videoSettings')}
          </Typography>
          <FormControl
            className={classes.formControl}
            margin="dense"
            variant="outlined"
          >
            <Select
              onChange={(e) => {
                onVideoDeviceChange(e.target.value as string);
              }}
              value={selectedVideoDevice}
            >
              {videoDevices.map((device) => {
                return (
                  <MenuItem key={device.deviceId} value={device.deviceId}>
                    {device.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
      </div>
    </div>
  );
};

export default DeviceSettingPanel;
