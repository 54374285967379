import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import {
  Conversation,
  IMEInput,
  MessageInstance,
} from '@zeals/shared-components';
import { Conversation as IConversation } from '@twilio/conversations/lib/conversation';

import useStyles from './styles';

type ChatProps = {
  conversation?: IConversation;
  messages: MessageInstance[];
};

const Chat: React.FC<ChatProps> = ({ conversation, messages }: ChatProps) => {
  const classes = useStyles({
    inputEnabled: !!conversation,
  });
  const { t: translate } = useTranslation();
  const [input, setInput] = useState('');

  return (
    <div className={classes.chatContainer}>
      <div className={classes.chatDialog}>
        <Conversation
          fade={false}
          messages={messages}
          style={{
            maxHeight: '100%',
            marginTop: 'auto',
          }}
          theme="light"
        />
      </div>
      {conversation && (
        <div className={classes.inputContainer}>
          <IMEInput
            aria-label="free-input"
            classes={{
              input: classes.input,
            }}
            disableUnderline
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                conversation.sendMessage(input);
                setInput('');
              }
            }}
            placeholder={translate('component.chat.inputPlaceholder')}
            value={input}
          />
          <Button
            id="chat-send"
            aria-label="send"
            className={classes.sendButton}
            onClick={() => {
              conversation.sendMessage(input);
              setInput('');
            }}
          >
            {translate('component.chat.sendButton')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default Chat;
