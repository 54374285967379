import { observable, action, runInAction, reaction } from 'mobx';
import { persist } from 'mobx-persist';
import firebase from 'firebase/app';

import {
  GoogleLoginRequest,
  UpdateAdminRequest,
} from '@zeals/shared-types/build/api/admin';
import { AdminInstance } from '@zeals/shared-types/build/admin';

import { RootStore } from './rootStore';
import adminApi from '../services/api/adminApi';

class AuthStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    // autorun monitors
    reaction(
      () => this.accessToken,
      () => {
        Promise.resolve().then(async () => {
          const token = await firebase.messaging().getToken();
          await this.updateAdmin({
            fcmToken: token,
          }).catch((err) => {
            console.warn(err);
          });
        });
      }
    );
  }

  @observable fetching = false;

  @observable registering = false;

  @persist @observable accessToken = '';

  @persist('object') @observable user: AdminInstance = null;

  @action reset = (): void => {
    this.accessToken = null;
    this.user = null;
  };

  @action getCurrentUser = async (): Promise<AdminInstance> => {
    if (this.fetching) return null;

    this.fetching = true;

    try {
      const response = await adminApi.getCurrentUser();
      if (response.error) {
        const { message } = response.error;
        throw new Error(message);
      }

      if (!response.data.admin) {
        throw new Error();
      }

      const { admin } = response.data;
      this.user = admin;

      return this.user;
    } finally {
      runInAction(() => {
        this.fetching = false;
      });
    }
  };

  @action loginWithGoogle = async (
    request: GoogleLoginRequest
  ): Promise<void> => {
    if (this.registering) return;

    this.registering = true;

    try {
      const googleResponse = await adminApi.loginWithGoogle(request);
      if (googleResponse.error) {
        const { message } = googleResponse.error;
        throw new Error(message);
      }

      runInAction(() => {
        this.accessToken = googleResponse.data.accessToken;
      });

      const currentUser = await adminApi.getCurrentUser();
      if (currentUser.error) {
        const { message } = currentUser.error;
        throw new Error(message);
      }

      if (!currentUser.data.admin) {
        throw new Error();
      }

      runInAction(() => {
        const { admin } = currentUser.data;
        this.user = admin;
      });
    } finally {
      runInAction(() => {
        this.registering = false;
      });
    }
  };

  @action updateAdmin = async (request: UpdateAdminRequest): Promise<void> => {
    if (this.fetching) return;

    this.fetching = true;

    try {
      const response = await adminApi.updateAdmin(request);
      if (response.error) {
        const { message } = response.error;
        throw new Error(message);
      }

      if (!response.data.admin) {
        throw new Error();
      }

      const { admin } = response.data;
      this.user = admin;
    } finally {
      runInAction(() => {
        this.fetching = false;
      });
    }
  };
}

export default AuthStore;
