import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { observer } from 'mobx-react';

import SettingsIcon from '@material-ui/icons/Settings';

import {
  CallEndConfirmationModal,
  EndCallButton,
  MicToggle,
  ScreenShareToggle,
  SessionEndErrorModal,
  VideocamToggle,
  IconButton,
  useTwilioConversation,
  useTwilioVideo,
  useParticipantTracks,
  EarthLoadingIndicator,
  Spacer,
  Participant,
} from '@zeals/shared-components';
import { matching } from '@zeals/shared-types';
import { ReservationInstance } from '@zeals/shared-types/build/reservation';
import { Typography } from '@material-ui/core';

import Chat from '../../components/ChatComponent';
import EnduserProfile from '../../components/EnduserProfile';
import HiddenVideo from '../../components/HiddenVideo';

import DeviceSettingModal from './DeviceSettingModal';
import EnduserExitModal from './EnduserExitModal';

import ChatVideoLoadingBlock from './loading/ChatVideoLoadingBlock';
import ChatRoomVideoBlock from './video/ChatRoomVideoBlock';
import OnHoldVideoToggle from './OnHoldVideoToggle';

import useStyles from './styles';
import { useStores } from '../../stores';
import { Routes } from '../../navigations/Routes';

import getTrackLoadingState from './getTrackLoadingState';

import config from '../../config';

const onHoldVideo = require('../../resources/video/his-on-hold-video.mp4')
  .default;

type ChatVideoPageParams = {
  roomId: string;
};

const ChatVideoPage: React.FC = () => {
  const classes = useStyles();
  const { t: translate } = useTranslation();
  const history = useHistory();
  const {
    authStore,
    matchingStore,
    settingStore,
    reservationStore,
    conversationStore,
    videoStore,
  } = useStores();
  const { roomId } = useParams<ChatVideoPageParams>();

  const onHoldVideoRef = useRef<HTMLVideoElement>();

  const [audioEnabled, setAudioEnabled] = useState(true);
  const [conversationToken, setConversationToken] = useState('');
  const [endingCall, setEndingCall] = useState(false);
  const [onHoldEnabled, setOnHoldEnabled] = useState(false);
  const [reservation, setReservation] = useState<ReservationInstance>();
  const [showCallEndConfirmation, setShowCallEndConfirmation] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showEnduserExitModal, setShowEnduserExitModal] = useState(false);
  const [screenShareEnabled, setScreenShareEnabled] = useState(false);
  const [settingDialogOpen, setSettingDialogOpen] = useState(false);
  const [videoEnabled, setVideoEnabled] = useState(true);
  const [videoToken, setVideoToken] = useState('');
  const [participantJoined, setParticipantJoined] = useState(false);

  const closeRoom = async () => {
    await Promise.all([
      reservationStore.updateReservation({
        id: reservation.id,
        startTime: dayjs(reservation.startTime)
          .subtract(5, 'minute')
          .toISOString(),
        endTime: dayjs().subtract(5, 'minute').toISOString(),
        additionalFields: {
          feedback: reservation.additionalFields.feedback,
          memo: reservationStore.memo,
        },
      }),
      matchingStore.closeRoom(matchingStore.room.id),
    ]);

    reservationStore.setMemo(translate('page.chatVideo.memo.default'));
    matchingStore.setRoom(null);
  };

  useEffect(() => {
    Promise.all([
      matchingStore.getRoom(roomId),
      reservationStore.listReservations({
        filter: {
          roomId,
        },
      }),
    ])
      .then(async ([, reservationResponse]) => {
        if (matchingStore.room.status === matching.RoomStatus.CLOSED) {
          setShowErrorModal(true);
          return;
        }

        if (
          reservationResponse &&
          reservationResponse.reservations &&
          reservationResponse.reservations.length > 0
        ) {
          const [remoteReservation] = reservationResponse.reservations;
          setReservation(remoteReservation);
          if (remoteReservation.additionalFields.memo) {
            reservationStore.setMemo(remoteReservation.additionalFields.memo);
          } else if (!reservationStore.memo) {
            // set default
            reservationStore.setMemo(translate('page.chatVideo.memo.default'));
          }
        }

        const [
          videoTokenResponse,
          conversationTokenResponse,
        ] = await Promise.all([
          videoStore.getVideoSessionToken({
            videoSessionId: matchingStore.room.videoSessionId,
          }),
          conversationStore.getConversationToken({
            conversationId: matchingStore.room.conversationId,
          }),
        ]);

        setVideoToken(videoTokenResponse.token);
        setConversationToken(conversationTokenResponse.data?.token);
      })
      .catch((err) => console.error(err));
  }, [matchingStore, videoStore, conversationStore, reservationStore, roomId]);

  const { conversation, messages } = useTwilioConversation(
    conversationToken,
    matchingStore.room && matchingStore.room.conversationId,
    authStore.user.id
  );

  const { room, remoteParticipants, error: twilioError } = useTwilioVideo({
    token: videoToken,
    name: matchingStore.room && matchingStore.room.videoSessionId,
    localTracksOptions: {
      video: {
        enabled: videoEnabled,
        options: {
          deviceId: settingStore.selectedVideoDevice,
        },
      },
      audio: {
        enabled: audioEnabled,
        options: {
          deviceId: settingStore.selectedAudioDevice,
        },
      },
      screen: {
        enabled: screenShareEnabled,
        options: {
          onStop: () => {
            setScreenShareEnabled(false);
            // turn the camera back on when screen share ended
            setVideoEnabled(true);
          },
        },
      },
      file: {
        enabled: onHoldEnabled,
        options: {
          ref: onHoldVideoRef,
        },
      },
    },
    connectOptions: {
      name: matchingStore.room && matchingStore.room.videoSessionId,
      ...config.twilioConnectOptions,
    },
  });

  useEffect(() => {
    if (twilioError) {
      closeRoom().then(() => setShowErrorModal(true));
    }
  }, [twilioError, matchingStore, roomId]);

  useEffect(() => {
    const beforeUnloadHandler = (e: BeforeUnloadEvent) => {
      if (!matchingStore.room) {
        return;
      }

      // Cancel the event
      e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
      // Chrome requires returnValue to be set
      // Custom message is deprecated so it's not possible to set custom message now
      e.returnValue = '';
    };

    window.addEventListener('beforeunload', beforeUnloadHandler);

    return () => {
      window.removeEventListener('beforeunload', beforeUnloadHandler);
    };
  }, [matchingStore.room]);

  useEffect(() => {
    // automatically turn off video when sharing screen
    if (screenShareEnabled) {
      setVideoEnabled(false);
    } else {
      // automatically turn on camera and mic once the screen share stopped
      setVideoEnabled(true);
    }
  }, [screenShareEnabled]);

  useEffect(() => {
    // automatically turn off screen share when enabling camera
    if (videoEnabled) {
      setScreenShareEnabled(false);
      setOnHoldEnabled(false);
    }
  }, [videoEnabled]);

  useEffect(() => {
    // automatically turn off onhold when enabling mic
    if (audioEnabled) {
      setOnHoldEnabled(false);
    }
  }, [audioEnabled]);

  useEffect(() => {
    // automatically turn off all other sources
    if (onHoldEnabled) {
      setScreenShareEnabled(false);
      setVideoEnabled(false);
      setAudioEnabled(false);
    } else {
      setVideoEnabled(true);
      setAudioEnabled(true);
    }
  }, [onHoldEnabled]);

  const [activeLocalVideoTrack, activeLocalAudioTrack] = useParticipantTracks(
    room?.localParticipant
  );

  useEffect(() => {
    // when there are no participant in the room, check the room status
    if (remoteParticipants.length === 0 && room && participantJoined) {
      setShowEnduserExitModal(true);
      setParticipantJoined(false);
    } else if (remoteParticipants.length > 0 && !participantJoined) {
      setParticipantJoined(true);
    }
  }, [
    matchingStore,
    history,
    roomId,
    room,
    remoteParticipants,
    participantJoined,
  ]);

  if (!room || !conversation) {
    if (showErrorModal) {
      return (
        <SessionEndErrorModal
          onConfirm={() => history.push(Routes.ShiftManagement)}
          open={showErrorModal}
        />
      );
    }
    return <ChatVideoLoadingBlock />;
  }

  const onScreenShareCancel = () => {
    setScreenShareEnabled(false);
  };

  const loadingState = getTrackLoadingState({
    camera: videoEnabled,
    mic: audioEnabled,
    screenshare: screenShareEnabled,
    onhold: onHoldEnabled,
    videoTrackExist: !!activeLocalVideoTrack,
    audioTrackExist: !!activeLocalAudioTrack,
  });

  return (
    <>
      <div className={classes.rootContainer}>
        <div className="chat">
          <div className="chatInfo">
            <EnduserProfile
              disableMemoEdit={false}
              enduser={matchingStore.enduser}
              memo={reservationStore.memo}
              onMemoChange={(memo) => {
                reservationStore.setMemo(memo);
              }}
            />
          </div>
          <div className="chatConversation">
            <Chat
              conversation={conversation}
              messages={messages.map((message) => {
                return {
                  ...message,
                  author:
                    message.direction === 'outgoing'
                      ? translate('component.chat.operatorName')
                      : matchingStore.enduser.name,
                };
              })}
            />
          </div>
        </div>
        <div className="video">
          <div className="webCamera">
            <div className={classes.remoteVideoContainer}>
              {remoteParticipants.length > 0 ? (
                <Participant
                  className={classes.remoteVideoTag}
                  participant={remoteParticipants[0]}
                />
              ) : (
                <div className={classes.loadingIndicator}>
                  <EarthLoadingIndicator />
                  <Spacer size="m" />
                  <Typography variant="h6" color="textSecondary">
                    {translate('page.video.pleaseWait')}
                  </Typography>
                </div>
              )}
            </div>
            <ChatRoomVideoBlock
              isScreenShared={screenShareEnabled}
              onScreenShareCancel={onScreenShareCancel}
              localParticipant={room.localParticipant}
              remotePartcipant={
                remoteParticipants.length > 0 ? remoteParticipants[0] : null
              }
            />
          </div>
          <div className="controls">
            <EndCallButton
              label={translate('common.buttons.endCall')}
              loading={endingCall}
              onClick={() => {
                setShowCallEndConfirmation(true);
              }}
            />
            <VideocamToggle
              label={translate('common.buttons.camera')}
              loading={loadingState.camera}
              value={videoEnabled}
              onChange={(value) => setVideoEnabled(value)}
            />
            <MicToggle
              label={translate('common.buttons.microphone')}
              loading={loadingState.mic}
              value={audioEnabled}
              onChange={(value) => setAudioEnabled(value)}
              track={activeLocalAudioTrack}
            />
            <ScreenShareToggle
              label={translate('common.buttons.screenShare')}
              loading={loadingState.screenshare}
              onChange={(value) => setScreenShareEnabled(value)}
              value={screenShareEnabled}
            />
            <OnHoldVideoToggle
              label={translate('common.buttons.onHold')}
              loading={loadingState.onhold}
              onChange={(value) => {
                setOnHoldEnabled(value);
              }}
              value={onHoldEnabled}
            />
            <IconButton
              id="setting-button"
              label={translate('common.buttons.settings')}
              onClick={() => setSettingDialogOpen((open) => !open)}
            >
              <SettingsIcon />
            </IconButton>
          </div>
        </div>
        <HiddenVideo
          videoRef={onHoldVideoRef}
          src={onHoldVideo}
          enabled={onHoldEnabled}
        />
        <DeviceSettingModal
          open={settingDialogOpen}
          onSave={() => setSettingDialogOpen((open) => !open)}
        />
      </div>
      <CallEndConfirmationModal
        loading={endingCall}
        onCancel={() => {
          setShowCallEndConfirmation(false);
          setEndingCall(false);
        }}
        onConfirm={() => {
          setEndingCall(true);
          closeRoom().then(() => {
            setEndingCall(false);
            history.push(Routes.ShiftManagement);
          });
        }}
        open={showCallEndConfirmation}
      />
      <SessionEndErrorModal
        onConfirm={() => history.push(Routes.ShiftManagement)}
        open={showErrorModal}
      />
      <EnduserExitModal
        open={showEnduserExitModal}
        onClose={() => {
          setShowEnduserExitModal(false);
        }}
      />
    </>
  );
};

export default observer(ChatVideoPage);
