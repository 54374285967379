"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const styles_1 = require("@material-ui/core/styles");
exports.default = styles_1.makeStyles({
    container: {
        alignItems: 'stretch',
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'Roboto',
        fontWeight: 200,
        overflow: 'scroll',
        justifyContent: 'flex-start',
        height: '100%',
        width: '100%',
        '& canvas': {
            boxShadow: '0 0 5px rgba(0, 0, 0, 0.7) !important',
            margin: '10px auto !important',
        },
    },
    zoomNavigatorContainer: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
    },
});
