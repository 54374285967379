import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import { Dialog as Modal, Grid, Typography } from '@material-ui/core';

import { Button, CompanyLogo } from '@zeals/shared-components';

import DeviceSettingPanel from '../../../components/DeviceSettingPanel';

import { useStores } from '../../../stores';
import useStyles from './styles';

type DeviceSettingDialogProps = {
  open: boolean;
  onSave: () => void;
};

// TODO: use the new dialog component in the upcoming error PR
const DeviceSettingDialog: React.FC<DeviceSettingDialogProps> = ({
  open,
  onSave,
}: DeviceSettingDialogProps) => {
  const classes = useStyles();
  const { t: translate } = useTranslation();
  const { settingStore } = useStores();

  if (!open) return null;

  return (
    <Modal classes={{ paper: classes.paper }} open>
      <Grid
        alignItems="center"
        className={classes.titleContainer}
        container
        item
        justify="center"
      >
        <Grid item className="logo">
          <CompanyLogo company="his" reserveFill />
        </Grid>
      </Grid>
      <div className={classes.videoSettingsContainer}>
        <div className="videoSettingsTitle">
          <Typography className="font">
            {translate('component.deviceSettingPanel.title')}
          </Typography>
        </div>
        <DeviceSettingPanel
          onAudioDeviceChange={(deviceId) =>
            settingStore.setSelectedAudioDevice(deviceId)
          }
          onVideoDeviceChange={(deviceId) =>
            settingStore.setSelectedVideoDevice(deviceId)
          }
          selectedAudioDevice={settingStore.selectedAudioDevice}
          selectedVideoDevice={settingStore.selectedVideoDevice}
        />
      </div>
      <div className={classes.buttonContainer}>
        <Button
          className={classes.saveButton}
          id="device-save"
          variant="rounded"
          onClick={() => {
            onSave();
          }}
        >
          {translate('page.chatVideo.settingDialog.save')}
        </Button>
      </div>
    </Modal>
  );
};

export default observer(DeviceSettingDialog);
