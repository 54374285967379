import { createContext, useContext } from 'react';
import { create } from 'mobx-persist';

import AuthStore from './authStore';
import ConversationStore from './conversationStore';
import NotificationStore from './notificationStore';
import SettingStore from './settingStore';
import VideoStore from './videoStore';
import MatchingStore from './matchingStore';
import EnduserStore from './enduserStore';
import ReservationStore from './reservationStore';

const namespace = '@ZEALS_DX_ADMIN';

const hydrate = create({
  storage: localStorage,
  jsonify: true,
});

export class RootStore {
  authStore = new AuthStore(this);

  conversationStore = new ConversationStore(this);

  notificationStore = new NotificationStore(this);

  settingStore = new SettingStore(this);

  videoStore = new VideoStore(this);

  matchingStore = new MatchingStore(this);

  enduserStore = new EnduserStore(this);

  reservationStore = new ReservationStore(this);
}

const hydrateAll = async (rootStore: RootStore): Promise<void> => {
  try {
    await Promise.all([
      hydrate(`${namespace}:auth`, rootStore.authStore),
      hydrate(`${namespace}:setting`, rootStore.settingStore),
      hydrate(`${namespace}:reservation`, rootStore.reservationStore),
    ]);
  } catch (err) {
    console.log(err);
    // clean localStorage
    localStorage.removeItem(`${namespace}:auth`);
    localStorage.removeItem(`${namespace}:setting`);
    localStorage.removeItem(`${namespace}:reservation`);
  }
};

const rootStore = new RootStore();

const RootStoreContext = createContext<RootStore>(rootStore);

const useStores = (): RootStore => {
  return useContext(RootStoreContext);
};

export { rootStore, useStores, hydrateAll, RootStoreContext };
