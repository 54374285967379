import React from 'react';
import { Button, Divider } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import {
  CallStatusBar,
  CompanyLogo,
  Participant,
  ParticipantInstance,
  NetworkStatus,
  useNetworkQualityLevel,
  INetworkMessage,
  INetworkLevel,
} from '@zeals/shared-components';
import { rgba } from '@zeals/shared-utils';

import useStyles from './styles';

type ChatRoomVideoBlockProps = {
  onScreenShareCancel: () => void;
  isScreenShared: boolean;
  localParticipant: ParticipantInstance;
  remotePartcipant: ParticipantInstance | null;
};

const ChatRoomVideoBlock: React.FC<ChatRoomVideoBlockProps> = ({
  onScreenShareCancel,
  isScreenShared,
  localParticipant,
  remotePartcipant,
}: ChatRoomVideoBlockProps) => {
  const operatorNetworkLevel = useNetworkQualityLevel(
    localParticipant,
    'admin'
  ) as INetworkLevel;
  const endUserNetworkLevel = useNetworkQualityLevel(
    remotePartcipant,
    'end-user'
  ) as INetworkLevel;
  const revertLocal = [...localParticipant.videoTracks.values()].some(
    (track) =>
      !track.trackName.startsWith('screen-share') &&
      !track.trackName.startsWith('local-file')
  );

  const classes = useStyles({ revertLocal });
  const { t: translate } = useTranslation();
  const theme = useTheme();

  const networkMessages: INetworkMessage = {
    noConnection: translate('page.networkNotification.noConnection'),
    lowConnection: translate('page.networkNotification.lowConnection'),
    highConnection: translate('page.networkNotification.highConnection'),
  };

  return (
    <div className={classes.chatRoomVideoContainer}>
      <div className="topRow">
        <div className="statusBarContainer">
          <div className="statusBar">
            <CallStatusBar
              style={{
                backgroundColor: rgba(theme.palette.common.black, 0.5),
                borderRadius: '50px',
                margin: '0px',
                padding: theme.spacing(3),
                width: 'calc(100% + 24px)',
              }}
              logo={<CompanyLogo company="his" reserveFill={false} />}
              showMicrophoneIcon={true}
              remotePartcipant={remotePartcipant}
            />
          </div>
        </div>
        <div className="localVideoContainer">
          <Participant
            className={classes.localVideoTag}
            local
            participant={localParticipant}
          />
        </div>
      </div>
      <div className="bottomRow">
        {isScreenShared ? (
          <div className="screenShare">
            <div className="screenShareText">
              {translate('page.chatVideo.video.screenShare.infoText')}
            </div>
            <div className="screenShareCancel">
              <Button
                id="sharescree-cancel"
                aria-label="shareScreenCancel"
                onClick={onScreenShareCancel}
              >
                {translate('page.chatVideo.video.screenShare.cancelText')}
              </Button>
            </div>
          </div>
        ) : null}
      </div>
      <div className={classes.networkStatusContainer}>
        <NetworkStatus
          networkMessages={networkMessages}
          anotherMsg={translate('page.networkNotification.endUser')}
          networkLevel={endUserNetworkLevel}
        />
        <Divider orientation="vertical" />
        <NetworkStatus
          networkMessages={networkMessages}
          anotherMsg={translate('page.networkNotification.admin')}
          networkLevel={operatorNetworkLevel}
        />
      </div>
    </div>
  );
};

export default ChatRoomVideoBlock;
