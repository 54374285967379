import { makeStyles } from '@material-ui/core/styles';

type ShiftManagementPageProps = {
  vacancyOpening: boolean;
};

const lgSpacing = {
  content: {
    padding: '24px',
  },
  label: {
    marginTop: '8px',
  },
  title: {
    paddingBottom: '8px',
    paddingTop: '8px',
  },
  username: {
    marginBottom: '24px',
  },
  videoSettings: {
    previewTitle: {
      marginTop: '16px',
    },
    marginTop: '24px',
  },
  videoTitle: {
    marginBottom: '24px',
  },
};

const xlSpacing = {
  content: {
    padding: '32px',
  },
  label: {
    marginTop: '16px',
  },
  title: {
    paddingBottom: '16px',
    paddingTop: '16px',
  },
  username: {
    marginBottom: '16px',
  },
  videoSettings: {
    previewTitle: {
      marginTop: '24px',
    },
    marginTop: '40px',
  },
  videoTitle: {
    marginBottom: '16px',
  },
};

export default makeStyles((theme) => ({
  circularProgress: {
    alignSelf: 'center',
    color: theme.palette.success.main,
  },
  label: {
    color: '#0D47A1',
    fontSize: '0.75rem',
    fontWeight: 700,
    marginBottom: '8px',
  },
  container: {
    width: '100vw',
    height: '100vh',
  },
  header: {
    height: '70px',
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    justifyContent: 'space-between',
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      width: '180px',
      fontSize: '1rem',
      '&>h5': {
        fontSize: '1em',
        fontWeight: '900',
      },
      '&> button': {
        color: theme.palette.grey[500],
        height: '100%',
        fontWeight: '700',
      },
    },
  },
  devider: {
    width: '100%',
  },
  link: {
    color: theme.palette.grey[600],
    cursor: 'pointer',
    fontWeight: 700,
  },
  shiftManagementContainer: {
    backgroundColor: theme.palette.grey[200],
    alignContent: 'center',
    display: 'grid',
    gridTemplateColumns: 'minmax(0, 1fr) minmax(200px, 764px) minmax(0, 1fr)',
    height: 'calc(100vh - 108px)',
    minHeight: '738px',
    minWidth: '566px',
    position: 'relative',
    boxShadow: '0px 5px 20px rgba(0, 87, 184, 0.1)',
    '& >p': {
      fontSize: '1rem',
      fontWeight: 'bold',
    },
    '& >.windowTitleContainer': {
      alignItems: 'center',
      color: theme.palette.common.white,
      display: 'flex',
      gridColumn: 2,
      justifyContent: 'center',
      maxHeight: '100%',
      maxWidth: '100%',
      position: 'relative',
      width: '100%',
      [theme.breakpoints.between('lg', 'xl')]: {
        paddingBottom: xlSpacing.title.paddingBottom,
        paddingTop: xlSpacing.title.paddingTop,
      },
      [theme.breakpoints.between('xs', 'md')]: {
        paddingBottom: lgSpacing.title.paddingBottom,
        paddingTop: lgSpacing.title.paddingTop,
      },
    },
    '& >.windowContentContainer': {
      backgroundColor: theme.palette.common.white,
      borderRadius: '30px',
      boxShadow: '0px 5px 20px rgba(0, 87, 184, 0.1)',
      gridColumn: 2,
      height: 'fit-content',
      position: 'relative',
      width: '100%',
      flexDirection: 'column',
      '& >.bodyTop': {
        margin: '40px',
        height: '40%',
        '&>.titleRow': {
          '&> h5': {
            fontSize: '1.25em',
            fontWeight: 700,
            [theme.breakpoints.between('lg', 'xl')]: {
              marginBottom: xlSpacing.username.marginBottom,
            },
            [theme.breakpoints.between('xs', 'md')]: {
              marginBottom: lgSpacing.username.marginBottom,
            },
          },
        },
        '& >.statusAction': {
          display: 'flex',
          '& >.statusRow': {
            flex: 1,
            [theme.breakpoints.between('lg', 'xl')]: {
              marginTop: xlSpacing.label.marginTop,
            },
            [theme.breakpoints.between('xs', 'md')]: {
              marginTop: lgSpacing.label.marginTop,
            },
            '& >.statusBlock': {
              display: 'block',
              height: '48px',
              width: '100%',
              '& >.bullet': {
                color: (props: ShiftManagementPageProps) => {
                  if (props.vacancyOpening) {
                    return theme.palette.success.main;
                  }

                  return theme.palette.error.main;
                },
                float: 'left',
                marginRight: '4px',
                marginTop: '5px',
                height: '10px',
                width: '10px',
              },
              '& >.statusText': {
                float: 'left',
                fontSize: '0.875rem',
                fontWeight: 400,
              },
            },
          },
          '& >.nameRow': {
            flex: 1,
            [theme.breakpoints.between('lg', 'xl')]: {
              marginTop: xlSpacing.label.marginTop,
            },
            [theme.breakpoints.between('xs', 'md')]: {
              marginTop: lgSpacing.label.marginTop,
            },
            '& >.usernameBlock': {
              display: 'block',
              '& >.usernameText': {
                fontSize: '1em',
                fontWeight: 700,
                marginBottom: '24px',
              },
            },
          },
          '& >.shiftStartButtonRow': {
            [theme.breakpoints.between('lg', 'xl')]: {
              marginTop: xlSpacing.label.marginTop,
            },
            [theme.breakpoints.between('xs', 'md')]: {
              marginTop: lgSpacing.label.marginTop,
            },
            '& >button': {
              borderRadius: '50px',
              fontSize: '0.875rem',
              fontWeight: 700,
              height: '56px',
              width: '240px',
            },
          },
        },
        '& >.areaRow': {
          [theme.breakpoints.between('lg', 'xl')]: {
            marginTop: xlSpacing.label.marginTop,
          },
          [theme.breakpoints.between('xs', 'md')]: {
            marginTop: lgSpacing.label.marginTop,
          },
          '& >.areaInputBlock': {
            display: 'block',
            maxWidth: '240px',
            width: '240px',
            '& >.areaInput': {
              border: '1px solid rgba(0, 82, 180, 0.5)',
              boxSizing: 'border-box',
              // borderRadius: '10px',
              width: '100%',
            },
          },
        },
      },
      '& >.bodyBottom': {
        margin: '40px',
        height: '60%',
        [theme.breakpoints.between('lg', 'xl')]: {
          marginTop: xlSpacing.videoSettings.marginTop,
        },
        [theme.breakpoints.between('xs', 'md')]: {
          marginTop: lgSpacing.videoSettings.marginTop,
        },
        '& >.videoSettingsTitle': {
          '& > h5': {
            fontSize: '1em',
            fontWeight: '700',
            [theme.breakpoints.between('lg', 'xl')]: {
              marginBottom: xlSpacing.username.marginBottom,
            },
            [theme.breakpoints.between('xs', 'md')]: {
              marginBottom: lgSpacing.username.marginBottom,
            },
          },
        },
        '& >.previewTitle': {
          [theme.breakpoints.between('lg', 'xl')]: {
            marginTop: xlSpacing.videoSettings.previewTitle.marginTop,
          },
          [theme.breakpoints.between('xs', 'md')]: {
            marginTop: lgSpacing.videoSettings.previewTitle.marginTop,
          },
        },
      },
    },
  },
}));
