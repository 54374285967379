import apisauce from 'apisauce';
import transformResponse from '@zeals/shared-utils';
import { api as apiTypes } from '@zeals/shared-types';
import { ApiResponseWrapper } from '@zeals/shared-types/build/api/error';

import {
  GetConversationTokenRequest,
  GetConversationTokenResponse,
} from '@zeals/shared-types/build/api/conversation';

import {
  ListConversationMessagesRequest,
  ListConversationMessagesResponse,
} from '@zeals/shared-types/build/conversation';
import { rootStore } from '../../stores/rootStore';

import config from '../../config';

const api = apisauce.create({
  baseURL: config.serverUrl,
  headers: {
    Accept: 'application/json;charset=UTF-8',
    'Content-Type': 'application/json',
  },
});

api.addRequestTransform((request) => {
  request.headers.Authorization = rootStore.authStore.accessToken;
});

api.addResponseTransform((response) => {
  if (response.status === apiTypes.error.HTTPStatusCode.Forbidden) {
    rootStore.authStore.reset();
  }
});

export default {
  getConversationToken: (
    request: GetConversationTokenRequest
  ): Promise<ApiResponseWrapper<GetConversationTokenResponse>> => {
    return transformResponse<GetConversationTokenResponse>(api.get, {
      url: `/admin/conversations/${request.conversationId}/token`,
    });
  },
  listConversationMessages: (
    request: ListConversationMessagesRequest
  ): Promise<ApiResponseWrapper<ListConversationMessagesResponse>> => {
    return transformResponse<ListConversationMessagesResponse>(api.get, {
      url: `/admin/conversations/${request.conversationId}/messages`,
    });
  },
};
