"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RoomStatus = void 0;
var RoomStatus;
(function (RoomStatus) {
    RoomStatus[RoomStatus["UNSPECIFIED"] = 0] = "UNSPECIFIED";
    RoomStatus[RoomStatus["AWAITING_MATCHING"] = 1] = "AWAITING_MATCHING";
    RoomStatus[RoomStatus["SCHEDULED"] = 2] = "SCHEDULED";
    RoomStatus[RoomStatus["IN_PROGRESS"] = 3] = "IN_PROGRESS";
    RoomStatus[RoomStatus["CLOSED"] = 4] = "CLOSED";
})(RoomStatus = exports.RoomStatus || (exports.RoomStatus = {}));
