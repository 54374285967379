"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const styles_1 = require("@material-ui/core/styles");
exports.default = styles_1.makeStyles(() => ({
    calendarContainer: {
        height: 'fit-content',
        minWidth: '375px',
        overflow: 'hidden',
        width: '100%',
    },
}));
