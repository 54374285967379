import { EnvironmentConfig, ENVS } from './types';

const productionConfig: EnvironmentConfig = {
  serverUrl: 'https://api.omotenashi.ai/v1',
  environment: ENVS.PRODUCTION,
  firebaseConfig: {
    apiKey: 'AIzaSyDXC8SIfng-JyxwyCnv6UeianoFxoQED7g',
    authDomain: 'zeals-dx-prd.firebaseapp.com',
    databaseURL: 'https://zeals-dx-prd.firebaseio.com',
    projectId: 'zeals-dx-prd',
    storageBucket: 'zeals-dx-prd.appspot.com',
    messagingSenderId: '129733590022',
    appId: '1:129733590022:web:f7926845586f8f28c35787',
    measurementId: 'G-YBV6G9D773',
  },
  tagManagerContainerId: 'GTM-KMDRXDZ',
  sentryDst:
    'https://71877d57a4e14a1db2b974c56e9f0ecc@o247680.ingest.sentry.io/5544243',
  sentryEnabled: true,
  twilioConnectOptions: {
    tracks: [],
    maxAudioBitrate: 16000,
    // https://www.twilio.com/docs/video/tutorials/developing-high-quality-video-applications#mobile-browser-grid-recommended-settings
    bandwidthProfile: {
      video: {
        mode: 'presentation',
        maxTracks: 6, // the recommendation is 4 but since we have to switch some tracks between so having some buffer here
        maxSubscriptionBitrate: 2500000,
        dominantSpeakerPriority: 'standard',
        renderDimensions: {
          high: { height: 1080, width: 1920 },
          standard: { height: 640, width: 360 },
        },
      },
    },
    dominantSpeaker: true,
    preferredVideoCodecs: [{ codec: 'VP8', simulcast: true }],
    networkQuality: { local: 3, remote: 1 },
  },
};

export default productionConfig;
