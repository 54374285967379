"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useNetworkQualityLevel = void 0;
const react_1 = require("react");
function useNetworkQualityLevel(participant, whom, levelThreshold = 3) {
    const [networkQualityLevel, setNetworkQualityLevel] = react_1.useState(participant === null || participant === void 0 ? void 0 : participant.networkQualityLevel);
    react_1.useEffect(() => {
        if (!participant)
            return null;
        const handleNewtorkQualityLevelChange = (newNetworkQualityLevel) => {
            setNetworkQualityLevel(newNetworkQualityLevel);
            if (newNetworkQualityLevel < levelThreshold) {
                console.warn(`Low network quality for ${whom}`, {
                    id: participant.identity,
                    nqLevel: participant.networkQualityLevel,
                    nqStats: JSON.stringify(participant.networkQualityStats, null, 2),
                });
            }
        };
        setNetworkQualityLevel(participant.networkQualityLevel);
        participant.on('networkQualityLevelChanged', handleNewtorkQualityLevelChange);
        return () => {
            participant.off('networkQualityLevelChanged', handleNewtorkQualityLevelChange);
        };
    }, [participant, whom, levelThreshold]);
    return networkQualityLevel;
}
exports.useNetworkQualityLevel = useNetworkQualityLevel;
