import apisauce from 'apisauce';
import transformResponse from '@zeals/shared-utils';
import { api as apiTypes } from '@zeals/shared-types';
import { ApiResponseWrapper } from '@zeals/shared-types/build/api/error';

import {
  GetVideoTokenRequest,
  GetVideoTokenResponse,
} from '@zeals/shared-types/build/api/video';
import {
  GetVideoSessionRequest,
  VideoSession,
} from '@zeals/shared-types/build/video';
import { rootStore } from '../../stores/rootStore';

import config from '../../config';

const api = apisauce.create({
  baseURL: config.serverUrl,
  headers: {
    Accept: 'application/json;charset=UTF-8',
    'Content-Type': 'application/json',
  },
});

api.addRequestTransform((request) => {
  request.headers.Authorization = rootStore.authStore.accessToken;
});

api.addResponseTransform((response) => {
  if (response.status === apiTypes.error.HTTPStatusCode.Forbidden) {
    rootStore.authStore.reset();
  }
});

export default {
  getVideoSession: (
    request: GetVideoSessionRequest
  ): Promise<ApiResponseWrapper<VideoSession>> => {
    return transformResponse<VideoSession>(api.get, {
      url: `/admin/videos/${request.id}`,
    });
  },
  getVideoSessionToken: (
    request: GetVideoTokenRequest
  ): Promise<ApiResponseWrapper<GetVideoTokenResponse>> => {
    return transformResponse<GetVideoTokenResponse>(api.get, {
      url: `/admin/videos/${request.videoSessionId}/token`,
    });
  },
};
