"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.searchToQuery = void 0;
function decode(s) {
    return decodeURIComponent(s.replace(/\+/g, ' '));
}
function toCamel(s) {
    return s.replace(/([-_][a-z])/gi, ($1) => {
        return $1.toUpperCase().replace('-', '').replace('_', '');
    });
}
function searchToQuery(searchString) {
    const queryString = searchString.substring(1);
    const keyValues = queryString.split('&');
    return keyValues.reduce((obj, keyVal) => {
        const [key, val] = keyVal.split('=');
        if (key && val) {
            const queryKey = toCamel(decode(key));
            const queryVal = decode(val);
            obj[queryKey] = queryVal;
        }
        return obj;
    }, {});
}
exports.searchToQuery = searchToQuery;
