"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const styles_1 = require("@material-ui/core/styles");
exports.default = styles_1.makeStyles((theme) => {
    return {
        container: ({ scale = 1 }) => {
            return {
                display: 'flex',
                alignItems: 'center',
                fontSize: `${scale * 10}px`,
                color: theme.palette.common.white,
            };
        },
        statusMessage: ({ scale = 1 }) => {
            return {
                marginRight: `${3 * scale}px`,
            };
        },
    };
});
