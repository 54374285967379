import { VendorConfig } from './types';

export type HISVendorConfig = VendorConfig & {
  REGIONS: {
    HOKKAIDO: string;
    TOHOKU: string;
    KANTO: string;
    CHUBU_HOKURIKU: string;
    KANSAI: string;
    CHUGOKU_SHIKOKU: string;
    KYUSHU: string;
  };
};

const his: HISVendorConfig = {
  domain: 'his',
  REGIONS: {
    HOKKAIDO: 'hokkaido',
    TOHOKU: 'tohoku',
    KANTO: 'kanto',
    CHUBU_HOKURIKU: 'chubu_hokuriku',
    KANSAI: 'kansai',
    CHUGOKU_SHIKOKU: 'chugoku_shikoku',
    KYUSHU: 'kyushu',
  },
};

export default his;
