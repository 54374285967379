"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const styles_1 = require("@material-ui/core/styles");
exports.default = styles_1.makeStyles((theme) => {
    return {
        icon: {
            '&:hover': {
                color: theme.palette.common.white,
                backgroundColor: theme.palette.grey[500],
            },
        },
        iconLabel: {
            color: theme.palette.common.white,
            flexDirection: 'column',
            fontSize: '10px',
            // prevent span inside a button trigger events
            pointerEvents: 'none',
        },
    };
});
