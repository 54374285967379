import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  button: {
    borderRadius: '50px',
  },
  buttonsContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    height: '100%',
    justifyContent: 'center',
    padding: theme.spacing(4),
    maxWidth: '289px',
    width: '100%',
  },
  paper: {
    alignItems: 'center',
    borderRadius: '30px',
    display: 'flex',
    justifyContent: 'center',
    maxHeight: 'fit-content',
    maxWidth: 'fit-content',
    width: '100%',
  },
}));
