import React, { useState, useEffect } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import Div100vh from 'react-div-100vh';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/analytics';
import 'firebase/messaging';

import { GoogleTagManager } from '@zeals/shared-components';

import { useStores, hydrateAll, RootStoreContext } from './stores';
import theme from './assets/theme/light';
import i18n from './i18n';
import config from './config';

import Router from './navigations/Router';
import SentryWrapper from './navigations/SentryWrapper';

import './assets/style/app.css';

i18n();

firebase.initializeApp(config.firebaseConfig);

const App = (): React.ReactElement => {
  const [hydrated, setHydrated] = useState(false);
  const rootStore = useStores();

  useEffect(() => {
    hydrateAll(rootStore).then(() => setHydrated(true));
  }, [rootStore]);

  if (!hydrated) return null;

  return (
    <GoogleTagManager containerId={config.tagManagerContainerId}>
      <SentryWrapper>
        <RootStoreContext.Provider value={rootStore}>
          <ThemeProvider theme={theme}>
            <Div100vh>
              <Router />
            </Div100vh>
          </ThemeProvider>
        </RootStoreContext.Provider>
      </SentryWrapper>
    </GoogleTagManager>
  );
};

export default App;
