import { observable, action, runInAction } from 'mobx';

import {
  GetVideoTokenRequest,
  GetVideoTokenResponse,
} from '@zeals/shared-types/build/api/video';
import { ApiResponseWrapper } from '@zeals/shared-types/build/api/error';

import {
  GetVideoSessionRequest,
  VideoSession,
} from '@zeals/shared-types/build/video';
import { RootStore } from './rootStore';

import api from '../services/api/videoApi';

class VideoStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable fetching = false;

  @action getVideoSession = async (
    request: GetVideoSessionRequest
  ): Promise<ApiResponseWrapper<VideoSession>> => {
    if (this.fetching) return null;

    this.fetching = true;

    try {
      // add the person to the conversation
      const response = await api.getVideoSession(request);

      if (response.error) {
        const { message } = response.error;
        throw new Error(message);
      }

      return response;
    } finally {
      runInAction(() => {
        this.fetching = false;
      });
    }
  };

  @action getVideoSessionToken = async (
    request: GetVideoTokenRequest
  ): Promise<GetVideoTokenResponse> => {
    if (this.fetching) return null;

    this.fetching = true;

    try {
      // add the person to the conversation
      const response = await api.getVideoSessionToken(request);
      if (response.error) {
        const { message } = response.error;
        throw new Error(message);
      }

      return response.data;
    } finally {
      runInAction(() => {
        this.fetching = false;
      });
    }
  };
}

export default VideoStore;
