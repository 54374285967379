"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const styles_1 = require("@material-ui/core/styles");
exports.default = styles_1.makeStyles((theme) => {
    return {
        container: {
            height: '100%',
            position: 'relative',
            width: '100%',
        },
        iconContainer: () => {
            return {
                alignItems: 'center',
                backgroundColor: theme.palette.grey[900],
                borderRadius: '10px',
                display: 'flex',
                height: '100%',
                justifyContent: 'center',
                position: 'relative',
            };
        },
        icon: {
            color: theme.palette.grey[600],
            height: '25%',
            width: '25%',
        },
    };
});
