"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const styles_1 = require("@material-ui/core/styles");
const StyledVideo = styles_1.styled('video')({
    objectFit: 'contain',
    overflow: 'hidden',
    position: 'absolute',
});
const Video = ({ className, onVideoStreamLoad, externalRef, style, track, }) => {
    const internalRef = react_1.useRef(null);
    react_1.useEffect(() => {
        const ref = externalRef || internalRef;
        const el = ref.current;
        el.muted = true;
        track.attach(el);
        return () => {
            track.detach(el);
        };
    }, [track]);
    // The local video track is mirrored if it is not facing the environment.
    return (react_1.default.createElement(StyledVideo, { className: className, onLoadedMetadata: onVideoStreamLoad, ref: externalRef || internalRef, style: style }));
};
exports.default = Video;
