import apisauce from 'apisauce';
import transformResponse from '@zeals/shared-utils';
import qs from 'qs';

import {
  ReservationInstance,
  UpdateReservationRequest,
  ListReservationsResponse,
  ListReservationsRequest,
} from '@zeals/shared-types/build/reservation';

import { api as apiTypes } from '@zeals/shared-types';
import { ApiResponseWrapper } from '@zeals/shared-types/build/api/error';

import config from '../../config';
import { rootStore } from '../../stores/rootStore';

const api = apisauce.create({
  baseURL: config.serverUrl,
  headers: {
    Accept: 'application/json;charset=UTF-8',
    'Content-Type': 'application/json',
  },
});

api.addRequestTransform((request) => {
  request.headers.Authorization = rootStore.authStore.accessToken;
});

api.addResponseTransform((response) => {
  if (response.status === apiTypes.error.HTTPStatusCode.Forbidden) {
    rootStore.authStore.reset();
  }
});

export default {
  listReservations: (
    request: ListReservationsRequest
  ): Promise<ApiResponseWrapper<ListReservationsResponse>> => {
    /**
     * @privateRemark
     * TODO explore an alternative that, for example, uses
     * `Promise<ApiResponse<ListReservationsResponse, Error>>` with
     * `api.get<ListReservationsResponse, Error>(...)`
     */
    return transformResponse<ListReservationsResponse>(api.get, {
      url: `/admin/reservations?${qs.stringify(request)}`,
    });
  },
  updateReservation: (
    request: UpdateReservationRequest
  ): Promise<ApiResponseWrapper<ReservationInstance>> => {
    return transformResponse<ReservationInstance>(api.patch, {
      payload: request,
      url: `/admin/reservations/${request.id}`,
    });
  },
};
