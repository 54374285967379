"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const styles_1 = require("@material-ui/core/styles");
const shared_utils_1 = require("@zeals/shared-utils");
exports.default = styles_1.makeStyles((theme) => {
    return {
        statusBar: {
            backgroundColor: shared_utils_1.rgba(theme.palette.grey[800], 0.5),
            padding: theme.spacing(1),
            borderRadius: theme.spacing(3),
        },
        logo: {
            maxHeight: '24px',
        },
        enabledIcon: {
            color: theme.palette.common.white,
        },
        disabledIcon: {
            color: theme.palette.error.main,
        },
        iconContainer: {
            display: 'flex',
        },
        clock: {
            color: theme.palette.common.white,
        },
    };
});
