"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const styles_1 = require("@material-ui/core/styles");
exports.default = styles_1.makeStyles((theme) => ({
    errorMessageContainer: {
        '& >.svgContainer': {
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
        },
        '& >.titleContainer': {
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            marginTop: '24px',
        },
        '& >.messageContainer': {
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            marginTop: theme.spacing(4),
        },
    },
    message: {
        color: theme.palette.common.black,
        fontSize: '1em',
        fontWeight: 700,
        textAlign: 'center',
        whiteSpace: 'pre-line',
    },
    title: {
        color: theme.palette.primary.main,
        fontSize: '1.25em',
        fontWeight: 900,
        textAlign: 'center',
        whiteSpace: 'pre-line',
    },
}));
