"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const styles_1 = require("@material-ui/core/styles");
exports.default = styles_1.makeStyles(() => {
    return {
        airplaneContainer: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'center',
            position: 'relative',
            width: '100%',
        },
        airplane: {
            animation: '$move 3s infinite',
            opacity: 0,
        },
        '@keyframes move': {
            '0%': {
                opacity: 0,
                transform: 'translateX(-25%)',
            },
            '50%': {
                opacity: 1,
                transform: 'translateX(25%)',
            },
            '100%': {
                opacity: 0,
                transform: 'translateX(25%)',
            },
        },
    };
});
