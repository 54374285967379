import dedent from 'dedent';
import { Translations } from '../types';

const jp: Translations = {
  common: {
    you: 'あなた',
    buttons: {
      cancel: 'キャンセル',
      camera: 'カメラ',
      close: '閉じる',
      endCall: '終了する',
      microphone: 'マイク',
      onHold: '保留動画',
      screenShare: '画面共有',
      settings: 'ビデオ設定',
    },
    errors: {
      callEndConfirmation: {
        confirmClose: '本当に終了しますか？',
      },
      enduserExit: {
        title: 'お客さまが退出されました。',
      },
      sessionEnd: {
        button: '戻る',
        message:
          'セッションが切れましたので\n再度、相談フォームにて入力して下さい。',
        title: 'セッションが切断されました。',
      },
      unknown: {
        button: '戻る',
        title: '予期せぬエラーが\n発生しました。',
      },
    },
    regions: {
      tbd: '決まってない',
      // doesmtic
      hokkaido: '北海道',
      tohoku: '東北',
      chubu_hokuriku: '中部・北陸',
      chugoku_shikoku: '中国・四国',
      koshinetsu: '甲信越',
      kanto: '関東',
      toukai: '東海',
      hokuriku: '北陸',
      kansai: '関西',
      sanin_sanyo: '山陰山陽',
      shikoku: '四国',
      kyushu: '九州',
      okinawa: '沖縄',
      okinawa_islands: '沖縄離島',
      // overseas
      asia: 'アジア',
      america_canada_latin_america: 'アメリカ・カナダ・中南米',
      ocenia_south_pacific_palau: 'オセアニア・南太平洋・パラオ',
      hawaii: 'ハワイ',
      europe_near_and_middle_east_africa: 'ヨーロッパ・中近東・アフリカ',
    },
  },
  component: {
    chat: {
      inputPlaceholder: 'テキストを入力してください',
      operatorName: 'あなた',
      sendButton: '投稿する',
      title: 'チャットログ',
    },
    boardingPass: {
      name: 'お名前',
      furigana: 'お名前（フリガナ）',
      phone: '電話番号',
      origin: '出発エリア',
      destination: '行き先',
      confirm: '相談をはじめる',
    },
    enduserProfile: {
      memo: {
        placeholder: 'テキストを入力してください',
        title: 'メモ',
      },
      userInfo: {
        destination: '行き先',
        furigana: 'お名前（フリガナ）',
        name: 'お名前',
        phone: '電話番号',
        livingArea: '居住エリア',
      },
    },
    deviceSettingPanel: {
      title: 'ビデオ設定',
      audioSettings: 'マイク設定',
      videoSettings: 'カメラ設定',
    },
    googleLoginButton: {
      text: 'Googleアカウントでログイン',
    },
  },
  page: {
    networkNotification: {
      admin: 'あなた：',
      endUser: 'お客さま：',
      noConnection: '接続なし',
      lowConnection: '接続不良',
      highConnection: '接続済み',
    },
    login: {
      title: 'ログイン',
    },
    chatVideo: {
      video: {
        screenShare: {
          cancelText: '完了',
          infoText: '画面が共有されてます',
        },
      },
      settingDialog: {
        save: '設定を保存する',
      },
      memo: {
        default: dedent`※この上部はメモ書き不可です。メモ書きはすべて下部の【その他メモ】へ記入してください。
        【経由元(LINE/JCOM/店舗)】
        【新規or既存】
        【空席照会or質問のみ】
        【性別】
        【年代とクラスター】
        【出発日と旅行日数】
        【ＰＡＸ数】
        【合計予算】
        【目的地】
        【発着地】
        【チャレンジ番号】
        【メールアドレス】
        【その他メモ(提案内容/懸念事項など)】`,
      },
    },
    shiftManagement: {
      boardingPass: {
        closeButton: 'ビデオ接客を始める',
        customerIsWaiting: 'お客さまが待ちしております！',
      },
      content: {
        areaLabel: 'エリア',
        buttons: {
          changeToAvailable: '対応可能にする',
          changeToUnavailable: '対応不可にする',
          logout: 'ログアウト',
        },
        status: {
          available: '対応可能',
          inPreperation: '準備中',
          title: 'ステータス',
          unavailable: '対応不可',
        },
        title: 'シフト管理',
        usernameLabel: '名前',
        videoSettings: {
          previewTitle: 'カメラプレビュー',
        },
      },
      window: {
        title: 'ビデオ接客受付',
      },
    },
    video: {
      cameraInstruction: '',
      pleaseWait: '少々お待ちください',
    },
  },
};

export default jp;
