// React
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Material
import CallEndIcon from '@material-ui/icons/CallEnd';
import {
  IconButton,
  Grid,
  CircularProgress,
  Typography,
} from '@material-ui/core';

// CSS
import useStyles from './styles';

// Routes
import { Routes } from '../../../navigations/Routes';

const ChatVideoLoadingBlock: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t: translate } = useTranslation();

  return (
    <Grid container className={classes.container} direction="column">
      <Grid className={classes.topBar} container item justify="flex-end">
        <Grid item>
          <IconButton
            id="call-end"
            aria-label="call-end"
            className={classes.endCallButton}
            onClick={() => {
              history.push(Routes.ShiftManagement);
            }}
          >
            <CallEndIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Grid
        className={classes.loadingText}
        container
        item
        spacing={2}
        direction="column"
        justify="center"
        alignItems="center"
      >
        <Grid item>
          <CircularProgress color="secondary" />
        </Grid>

        <Grid item>
          <Typography variant="h6" color="primary">
            {translate('page.video.pleaseWait')}
          </Typography>
        </Grid>

        <Grid item>
          <Typography variant="body1">
            {translate('page.video.cameraInstruction')}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ChatVideoLoadingBlock;
