"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const styles_1 = require("@material-ui/core/styles");
const shared_utils_1 = require("@zeals/shared-utils");
exports.default = styles_1.makeStyles((theme) => {
    return {
        buttonArea: {
            backgroundColor: theme.palette.common.white,
            borderRadius: '10px 10px 20px 20px',
            height: '100%',
            padding: theme.spacing(4),
        },
        companyHeader: {
            backgroundColor: theme.palette.primary.light,
            borderTopRightRadius: '24px 24px',
            borderTopLeftRadius: '24px 24px',
            height: '60px',
            padding: theme.spacing(2),
        },
        label: {
            color: shared_utils_1.rgba(theme.palette.common.black, 0.2),
            fontSize: '0.875em',
            fontWeight: 'bold',
        },
        logo: {
            maxHeight: '40px',
        },
        sessionDescription: {
            marginTop: theme.spacing(4),
            '& >.font': {
                fontSize: '0.875em',
                fontWeight: 'bold',
                whiteSpace: 'break-spaces',
            },
        },
        text: {
            fontSize: '1em',
            maxWidth: '167px',
            paddingRight: '8px',
            wordBreak: 'break-all',
        },
        textBold: {
            fontSize: '1em',
            fontWeight: 'bold',
        },
        userInfoContainer: {
            backgroundColor: theme.palette.common.white,
            borderBottom: `2px dashed ${shared_utils_1.rgba(theme.palette.common.black, 0.2)}`,
            borderRadius: '0px 0px 10px 10px',
            color: theme.palette.common.black,
            display: 'grid',
            gridTemplateAreas: `
      "alert alert alert alert"
      "name name kana kana"
      "phone phone phone phone"
      "origin origin origin origin"
      "destination destination destination destination"
    `,
            paddingBottom: theme.spacing(4),
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(4),
            paddingTop: (props) => (props.alertText ? 0 : '24px'),
            position: 'relative',
            rowGap: `${theme.spacing(2)}px`,
            width: '100%',
        },
        alertInfo: {
            alignItems: 'center',
            gridArea: 'alert',
            marginBottom: theme.spacing(3),
            marginTop: theme.spacing(4),
            '& >.font': {
                color: theme.palette.primary.main,
                fontSize: '1.25em',
                fontWeight: 700,
            },
        },
        userInfoName: {
            gridArea: 'name',
        },
        userInfoKana: {
            gridArea: 'kana',
        },
        userInfoPhone: {
            gridArea: 'phone',
        },
        userInfoOrigin: {
            gridArea: 'origin',
        },
        userInfoDestination: {
            gridArea: 'destination',
        },
    };
});
