"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const styles_1 = require("@material-ui/core/styles");
exports.default = styles_1.makeStyles(() => ({
    container: {
        flexFlow: 'wrap',
        overflow: 'hidden',
        overflowY: 'scroll',
        maskImage: (props) => props.fade
            ? 'linear-gradient(to bottom, transparent, black 25%)'
            : 'none',
        padding: '0px 24px 0px 24px',
        scrollbarWidth: 'none' /* Firefox */,
        '-ms-overflow-style': 'none' /* Internet Explorer 10+ */,
        '-webkit-mask-image': (props) => props.fade
            ? 'linear-gradient(to bottom, transparent, black 25%)'
            : 'none',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
}));
