"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useLocalTracks = exports.useLocalVideoFileTrack = exports.useScreenShare = exports.useLocalAudio = exports.useLocalVideo = void 0;
const react_1 = require("react");
const twilio_video_1 = __importStar(require("twilio-video"));
const uuid_1 = require("uuid");
const DEFAULT_VIDEO_OPTIONS = {
    height: 640,
    width: 360,
    frameRate: 24,
    aspectRatio: 640 / 360,
};
const DEFAULT_AUDIO_OPTIONS = {
    echoCancellation: true,
    noiseSuppression: true,
    autoGainControl: true,
};
const DEFAULT_SCREEN_SHARE_OPTIONS = {
    audio: false,
    video: {
        frameRate: 10,
        height: 1080,
        width: 1920,
    },
};
exports.useLocalVideo = (enabled, options) => {
    const videoTrackRef = react_1.useRef();
    react_1.useEffect(() => {
        if (enabled) {
            // cleanup old track if there are any
            // create new track based on the new config
            twilio_video_1.default.createLocalVideoTrack(Object.assign(Object.assign({}, DEFAULT_VIDEO_OPTIONS), options)).then((newTrack) => {
                var _a;
                videoTrackRef.current = newTrack;
                (_a = options === null || options === void 0 ? void 0 : options.onStart) === null || _a === void 0 ? void 0 : _a.call(options, newTrack);
            });
        }
        const cleanup = () => {
            var _a;
            if (videoTrackRef.current) {
                videoTrackRef.current.stop();
                (_a = options === null || options === void 0 ? void 0 : options.onStop) === null || _a === void 0 ? void 0 : _a.call(options, videoTrackRef.current);
                // reset ref
                videoTrackRef.current = null;
            }
        };
        return cleanup;
        // only one of these change will trigger it to do something
    }, [enabled, options.deviceId]);
    if (!enabled) {
        return null;
    }
    return videoTrackRef.current;
};
exports.useLocalAudio = (enabled, options) => {
    const audioTrackRef = react_1.useRef();
    react_1.useEffect(() => {
        if (enabled) {
            // cleanup old track if there are any
            // create new track based on the new config
            twilio_video_1.default.createLocalAudioTrack(Object.assign(Object.assign({}, DEFAULT_AUDIO_OPTIONS), options)).then((newTrack) => {
                var _a;
                audioTrackRef.current = newTrack;
                (_a = options === null || options === void 0 ? void 0 : options.onStart) === null || _a === void 0 ? void 0 : _a.call(options, newTrack);
            });
        }
        const cleanup = () => {
            var _a;
            if (audioTrackRef.current) {
                audioTrackRef.current.stop();
                (_a = options === null || options === void 0 ? void 0 : options.onStop) === null || _a === void 0 ? void 0 : _a.call(options, audioTrackRef.current);
                // reset ref
                audioTrackRef.current = null;
            }
        };
        return cleanup;
        // only one of these change will trigger it to do something
    }, [enabled, options.deviceId]);
    if (!enabled) {
        return null;
    }
    return audioTrackRef.current;
};
exports.useScreenShare = (enabled, options) => {
    const screenShareTrackRef = react_1.useRef();
    react_1.useEffect(() => {
        if (enabled) {
            // cleanup old track if there are any
            // create new track based on the new config
            const mediaDevices = navigator.mediaDevices;
            mediaDevices
                .getDisplayMedia(Object.assign(Object.assign({}, DEFAULT_SCREEN_SHARE_OPTIONS), { video: Object.assign(Object.assign({}, DEFAULT_SCREEN_SHARE_OPTIONS.video), options) }))
                .then((stream) => {
                var _a;
                const mediaTrack = stream.getVideoTracks()[0];
                // assume only one video track, sharing sounds on screen is not supported for now
                const newTrack = new twilio_video_1.default.LocalVideoTrack(mediaTrack, {
                    logLevel: 'error',
                    name: `screen-share-${uuid_1.v4()}`,
                });
                // for the case where user end the sharing in browser level
                mediaTrack.onended = () => {
                    var _a;
                    if (screenShareTrackRef.current) {
                        screenShareTrackRef.current.stop();
                        (_a = options === null || options === void 0 ? void 0 : options.onStop) === null || _a === void 0 ? void 0 : _a.call(options, newTrack);
                        screenShareTrackRef.current = null;
                    }
                };
                (_a = options === null || options === void 0 ? void 0 : options.onStart) === null || _a === void 0 ? void 0 : _a.call(options, newTrack);
                screenShareTrackRef.current = newTrack;
            });
        }
        const cleanup = () => {
            var _a;
            if (screenShareTrackRef.current) {
                screenShareTrackRef.current.stop();
                (_a = options === null || options === void 0 ? void 0 : options.onStop) === null || _a === void 0 ? void 0 : _a.call(options, screenShareTrackRef.current);
                // reset ref
                screenShareTrackRef.current = null;
            }
        };
        return cleanup;
        // only one of these change will trigger it to do something
    }, [enabled, options.deviceId]);
    if (!enabled) {
        return null;
    }
    return screenShareTrackRef.current;
};
exports.useLocalVideoFileTrack = (enabled, options) => {
    const [trackSet, setTrackSet] = react_1.useState([null, null]);
    react_1.useEffect(() => {
        if (!options.ref)
            return () => { };
        const el = options.ref.current;
        const trackExist = trackSet.every((track) => track);
        const onCanPlayThrough = () => {
            var _a, _b;
            if (enabled && !trackExist) {
                const stream = el.captureStream(30);
                // capture audio stream from video
                const audioCtx = new AudioContext();
                const source = audioCtx.createMediaElementSource(el);
                const dest = audioCtx.createMediaStreamDestination();
                source.connect(dest);
                const newVideoTrack = new twilio_video_1.LocalVideoTrack(stream.getVideoTracks()[0], {
                    logLevel: 'error',
                    name: `local-file-video-track-${uuid_1.v4()}`,
                });
                const newAudioTrack = new twilio_video_1.LocalAudioTrack(dest.stream.getAudioTracks()[0], {
                    logLevel: 'error',
                    name: `local-file-audio-track-${uuid_1.v4()}`,
                });
                setTrackSet([newVideoTrack, newAudioTrack]);
                (_a = options === null || options === void 0 ? void 0 : options.onStart) === null || _a === void 0 ? void 0 : _a.call(options, newVideoTrack);
                (_b = options === null || options === void 0 ? void 0 : options.onStart) === null || _b === void 0 ? void 0 : _b.call(options, newAudioTrack);
                el.muted = false;
                el.play();
            }
        };
        if (el) {
            el.oncanplaythrough = onCanPlayThrough;
        }
        return () => {
            if (enabled && trackExist) {
                trackSet.forEach((track) => {
                    var _a;
                    track.stop();
                    (_a = options === null || options === void 0 ? void 0 : options.onStop) === null || _a === void 0 ? void 0 : _a.call(options, track);
                });
                setTrackSet([null, null]);
            }
        };
    }, [options.ref, enabled, trackSet]);
    if (!enabled) {
        return [null, null];
    }
    return trackSet;
};
exports.useLocalTracks = ({ video, audio, screen, file = { enabled: false, options: { ref: null } }, }) => {
    const videoTrack = exports.useLocalVideo(video.enabled, video.options);
    const audioTrack = exports.useLocalAudio(audio.enabled, audio.options);
    const screenShareTrack = exports.useScreenShare(screen.enabled, screen.options);
    const localFileTracks = exports.useLocalVideoFileTrack(file.enabled, file.options);
    return [videoTrack, audioTrack, screenShareTrack, ...localFileTracks].filter(Boolean);
};
