import { makeStyles, Theme } from '@material-ui/core/styles';

type ScreenShareTogglePropsStyleProps = {
  value: boolean;
};

export default makeStyles<Theme, ScreenShareTogglePropsStyleProps>((theme) => {
  return {
    icon: {
      color: (props) =>
        props.value ? theme.palette.success.main : theme.palette.grey[400],
    },
  };
});
