"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAudioInputDevices = exports.useVideoInputDevices = exports.useDevices = void 0;
const react_1 = require("react");
exports.useDevices = () => {
    const [devices, setDevices] = react_1.useState([]);
    react_1.useEffect(() => {
        const getDevices = async () => {
            await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
            navigator.mediaDevices.enumerateDevices().then((dvs) => setDevices(dvs));
        };
        navigator.mediaDevices.addEventListener('devicechange', getDevices);
        getDevices();
        return () => {
            navigator.mediaDevices.removeEventListener('devicechange', getDevices);
        };
    }, []);
    return devices;
};
exports.useVideoInputDevices = () => {
    const devices = exports.useDevices();
    const videoDevices = react_1.useMemo(() => {
        return devices.filter((device) => device.kind === 'videoinput');
    }, [devices]);
    return videoDevices;
};
exports.useAudioInputDevices = () => {
    const devices = exports.useDevices();
    const audioDevices = react_1.useMemo(() => {
        return devices.filter((device) => device.kind === 'audioinput');
    }, [devices]);
    return audioDevices;
};
