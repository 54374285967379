"use strict";
/* eslint-disable no-bitwise */
Object.defineProperty(exports, "__esModule", { value: true });
exports.HTTPStatusCode = exports.GrpcStatus = void 0;
// Aligning with grpc status code since we will be migrate to grpc in future
// https://github.com/grpc/grpc/blob/master/doc/statuscodes.md
var GrpcStatus;
(function (GrpcStatus) {
    GrpcStatus[GrpcStatus["OK"] = 0] = "OK";
    GrpcStatus[GrpcStatus["CANCELLED"] = 1] = "CANCELLED";
    GrpcStatus[GrpcStatus["UNKNOWN"] = 2] = "UNKNOWN";
    GrpcStatus[GrpcStatus["INVALID_ARGUMENT"] = 3] = "INVALID_ARGUMENT";
    GrpcStatus[GrpcStatus["DEADLINE_EXCEEDED"] = 4] = "DEADLINE_EXCEEDED";
    GrpcStatus[GrpcStatus["NOT_FOUND"] = 5] = "NOT_FOUND";
    GrpcStatus[GrpcStatus["ALREADY_EXISTS"] = 6] = "ALREADY_EXISTS";
    GrpcStatus[GrpcStatus["PERMISSION_DENIED"] = 7] = "PERMISSION_DENIED";
    GrpcStatus[GrpcStatus["RESOURCE_EXHAUSTED"] = 8] = "RESOURCE_EXHAUSTED";
    GrpcStatus[GrpcStatus["FAILED_PRECONDITION"] = 9] = "FAILED_PRECONDITION";
    GrpcStatus[GrpcStatus["ABORTED"] = 10] = "ABORTED";
    GrpcStatus[GrpcStatus["OUT_OF_RANGE"] = 11] = "OUT_OF_RANGE";
    GrpcStatus[GrpcStatus["UNIMPLEMENTED"] = 12] = "UNIMPLEMENTED";
    GrpcStatus[GrpcStatus["INTERNAL"] = 13] = "INTERNAL";
    GrpcStatus[GrpcStatus["UNAVAILABLE"] = 14] = "UNAVAILABLE";
    GrpcStatus[GrpcStatus["DATA_LOSS"] = 15] = "DATA_LOSS";
    GrpcStatus[GrpcStatus["UNAUTHENTICATED"] = 16] = "UNAUTHENTICATED";
})(GrpcStatus || (GrpcStatus = {}));
exports.GrpcStatus = GrpcStatus;
// https://gist.github.com/FabianLauer/a8bafdc59bf20c1d1fad02b95d32656d
//
// Copyright (C) Fabian Lauer, 2018
//
// THIS SOFTWARE IS PROVIDED BY THE COPYRIGHT HOLDERS AND CONTRIBUTORS
// “AS IS” AND ANY EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NO
// LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS
// FOR A PARTICULAR PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL THE
// COPYRIGHT OWNER OR CONTRIBUTORS BE LIABLE FOR ANY DIRECT, INDIRECT,
// INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL DAMAGES (INCLUDING,
// BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES;
// LOSS OF USE, DATA, OR PROFITS; OR BUSINESS INTERRUPTION) HOWEVER
// CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER IN CONTRACT, STRICT
// LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR OTHERWISE) ARISING IN
// ANY WAY OUT OF THE USE OF THIS SOFTWARE, EVEN IF ADVISED OF THE
// POSSIBILITY OF SUCH DAMAGE.
//
var HTTPStatusCode;
(function (HTTPStatusCode) {
    HTTPStatusCode[HTTPStatusCode["Continue"] = 100] = "Continue";
    HTTPStatusCode[HTTPStatusCode["SwitchingProtocols"] = 101] = "SwitchingProtocols";
    HTTPStatusCode[HTTPStatusCode["Processing"] = 102] = "Processing";
    HTTPStatusCode[HTTPStatusCode["EarlyHints"] = 103] = "EarlyHints";
    /**
     * All `1xx` status codes.
     */
    HTTPStatusCode[HTTPStatusCode["InformationalResponses"] = 103] = "InformationalResponses";
    HTTPStatusCode[HTTPStatusCode["OK"] = 200] = "OK";
    HTTPStatusCode[HTTPStatusCode["Created"] = 201] = "Created";
    HTTPStatusCode[HTTPStatusCode["Accepted"] = 202] = "Accepted";
    HTTPStatusCode[HTTPStatusCode["NonAuthoritativeInformation"] = 203] = "NonAuthoritativeInformation";
    HTTPStatusCode[HTTPStatusCode["NoContent"] = 204] = "NoContent";
    HTTPStatusCode[HTTPStatusCode["ResetContent"] = 205] = "ResetContent";
    HTTPStatusCode[HTTPStatusCode["PartialContent"] = 206] = "PartialContent";
    HTTPStatusCode[HTTPStatusCode["MultiStatus"] = 207] = "MultiStatus";
    HTTPStatusCode[HTTPStatusCode["AlreadyReported"] = 208] = "AlreadyReported";
    HTTPStatusCode[HTTPStatusCode["IMUsed"] = 226] = "IMUsed";
    /**
     * All `2xx` status codes.
     */
    HTTPStatusCode[HTTPStatusCode["Success"] = 255] = "Success";
    HTTPStatusCode[HTTPStatusCode["MultipleChoices"] = 300] = "MultipleChoices";
    HTTPStatusCode[HTTPStatusCode["MovedPermanently"] = 301] = "MovedPermanently";
    HTTPStatusCode[HTTPStatusCode["Found"] = 302] = "Found";
    HTTPStatusCode[HTTPStatusCode["SeeOther"] = 303] = "SeeOther";
    HTTPStatusCode[HTTPStatusCode["NotModified"] = 304] = "NotModified";
    HTTPStatusCode[HTTPStatusCode["UseProxy"] = 305] = "UseProxy";
    HTTPStatusCode[HTTPStatusCode["SwitchProxy"] = 306] = "SwitchProxy";
    HTTPStatusCode[HTTPStatusCode["TemporaryRedirect"] = 307] = "TemporaryRedirect";
    HTTPStatusCode[HTTPStatusCode["PermanentRedirect"] = 308] = "PermanentRedirect";
    /**
     * All `3xx` status codes.
     */
    HTTPStatusCode[HTTPStatusCode["Redirection"] = 319] = "Redirection";
    HTTPStatusCode[HTTPStatusCode["BadRequest"] = 400] = "BadRequest";
    HTTPStatusCode[HTTPStatusCode["Unauthorized"] = 401] = "Unauthorized";
    HTTPStatusCode[HTTPStatusCode["PaymentRequired"] = 402] = "PaymentRequired";
    HTTPStatusCode[HTTPStatusCode["Forbidden"] = 403] = "Forbidden";
    HTTPStatusCode[HTTPStatusCode["NotFound"] = 404] = "NotFound";
    HTTPStatusCode[HTTPStatusCode["MethodNotAllowed"] = 405] = "MethodNotAllowed";
    HTTPStatusCode[HTTPStatusCode["NotAcceptable"] = 406] = "NotAcceptable";
    HTTPStatusCode[HTTPStatusCode["ProxyAuthenticationRequired"] = 407] = "ProxyAuthenticationRequired";
    HTTPStatusCode[HTTPStatusCode["RequestTimeout"] = 408] = "RequestTimeout";
    HTTPStatusCode[HTTPStatusCode["Conflict"] = 409] = "Conflict";
    HTTPStatusCode[HTTPStatusCode["Gone"] = 410] = "Gone";
    HTTPStatusCode[HTTPStatusCode["LengthRequired"] = 411] = "LengthRequired";
    HTTPStatusCode[HTTPStatusCode["PreconditionFailed"] = 412] = "PreconditionFailed";
    HTTPStatusCode[HTTPStatusCode["PayloadTooLarge"] = 413] = "PayloadTooLarge";
    HTTPStatusCode[HTTPStatusCode["URITooLong"] = 414] = "URITooLong";
    HTTPStatusCode[HTTPStatusCode["UnsupportedMediaType"] = 415] = "UnsupportedMediaType";
    HTTPStatusCode[HTTPStatusCode["RangeNotSatisfiable"] = 416] = "RangeNotSatisfiable";
    HTTPStatusCode[HTTPStatusCode["ExpectationFailed"] = 417] = "ExpectationFailed";
    HTTPStatusCode[HTTPStatusCode["ImATeapot"] = 418] = "ImATeapot";
    HTTPStatusCode[HTTPStatusCode["MisdirectedRequest"] = 421] = "MisdirectedRequest";
    HTTPStatusCode[HTTPStatusCode["UnprocessableEntity"] = 422] = "UnprocessableEntity";
    HTTPStatusCode[HTTPStatusCode["Locked"] = 423] = "Locked";
    HTTPStatusCode[HTTPStatusCode["FailedDependency"] = 424] = "FailedDependency";
    HTTPStatusCode[HTTPStatusCode["UpgradeRequired"] = 426] = "UpgradeRequired";
    HTTPStatusCode[HTTPStatusCode["PreconditionRequired"] = 428] = "PreconditionRequired";
    HTTPStatusCode[HTTPStatusCode["TooManyRequests"] = 429] = "TooManyRequests";
    HTTPStatusCode[HTTPStatusCode["RequestHeaderFieldsTooLarge"] = 431] = "RequestHeaderFieldsTooLarge";
    HTTPStatusCode[HTTPStatusCode["UnavailableForLegalReasons"] = 451] = "UnavailableForLegalReasons";
    /**
     * All `4xx` error codes.
     */
    HTTPStatusCode[HTTPStatusCode["ClientErrors"] = 511] = "ClientErrors";
    HTTPStatusCode[HTTPStatusCode["InternalServerError"] = 500] = "InternalServerError";
    HTTPStatusCode[HTTPStatusCode["NotImplemented"] = 501] = "NotImplemented";
    HTTPStatusCode[HTTPStatusCode["BadGateway"] = 502] = "BadGateway";
    HTTPStatusCode[HTTPStatusCode["ServiceUnavailable"] = 503] = "ServiceUnavailable";
    HTTPStatusCode[HTTPStatusCode["GatewayTimeout"] = 504] = "GatewayTimeout";
    HTTPStatusCode[HTTPStatusCode["HTTPVersionNotSupported"] = 505] = "HTTPVersionNotSupported";
    HTTPStatusCode[HTTPStatusCode["VariantAlsoNegotiates"] = 506] = "VariantAlsoNegotiates";
    HTTPStatusCode[HTTPStatusCode["InsufficientStorage"] = 507] = "InsufficientStorage";
    HTTPStatusCode[HTTPStatusCode["LoopDetected"] = 508] = "LoopDetected";
    HTTPStatusCode[HTTPStatusCode["NotExtended"] = 510] = "NotExtended";
    HTTPStatusCode[HTTPStatusCode["NetworkAuthenticationRequired"] = 511] = "NetworkAuthenticationRequired";
    /**
     * All `5xx` error codes.
     */
    HTTPStatusCode[HTTPStatusCode["ServerErrors"] = 511] = "ServerErrors";
})(HTTPStatusCode || (HTTPStatusCode = {}));
exports.HTTPStatusCode = HTTPStatusCode;
