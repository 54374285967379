import { isIOS } from 'react-device-detect';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  rootContainer: {
    display: 'grid',
    gridAutoColumns: 'minmax(0, 1fr)',
    gridAutoRows: 'minmax(0, 1fr)',
    gridTemplateAreas: `"video chat"`,
    height: '100%',
    minHeight: isIOS ? '-webkit-fill-available' : '100vh',
    overflow: 'hidden',
    maxHeight: '100%',
    maxWidth: '100%',
    width: '100%',
    '& >.chat': {
      background: theme.palette.common.white,
      display: 'grid',
      gridArea: 'chat',
      gridTemplateRows: 'minmax(0, auto) minmax(0, 1fr)',
      '& >.chatInfo': {
        boxShadow: '0px 2px 1px rgba(0, 87, 184, 0.1)',
        gridRow: 1,
      },
      '& >.chatConversation': {
        gridRow: 2,
      },
    },
    '& >.video': {
      background: theme.palette.grey[800],
      display: 'grid',
      gridArea: 'video',
      gridTemplateRows: 'minmax(0, 1fr) minmax(0, auto)',
      position: 'relative',
      '& >.webcamera': {
        gridRow: 1,
        position: 'relative',
      },
    },
  },
  fullWidthCenter: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
