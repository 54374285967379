type LoadingStateProps = {
  camera: boolean;
  mic: boolean;
  screenshare: boolean;
  onhold: boolean;
  videoTrackExist: boolean;
  audioTrackExist: boolean;
};

type LoadingStateResponse = {
  camera: boolean;
  mic: boolean;
  screenshare: boolean;
  onhold: boolean;
};

// Since the logic of the video stack loading is so complicated, splitting a util here to make the code more readable
export default ({
  camera,
  mic,
  screenshare,
  onhold,
  videoTrackExist,
  audioTrackExist,
}: LoadingStateProps): LoadingStateResponse => {
  // camera
  // loading when camera enabled / screenshare disabled / onhold disabled / video track does not exist
  const cameraDeps = [camera, !screenshare, !onhold, !videoTrackExist];

  // mic
  // loading when mic enabled / onhold disabled / audio track does not exist
  const micDeps = [mic, !onhold, !audioTrackExist];

  // screenshare
  // loading when screenshare enabled / camera disabled / onhold disabled / video track does not exist
  const screenshareDeps = [screenshare, !camera, !onhold, !videoTrackExist];

  // onhold
  // loading when onhol enabled / camera disabled / screenshare disabled / video track does not exist
  const onholdDeps = [onhold, !camera, !screenshare, !videoTrackExist];

  return {
    camera: cameraDeps.every((v) => v),
    mic: micDeps.every((v) => v),
    screenshare: screenshareDeps.every((v) => v),
    onhold: onholdDeps.every((v) => v),
  };
};
