import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  paper: {
    background: theme.palette.grey[600],
    borderRadius: '30px',
    overflow: 'hidden',
    width: 'fit-content',
  },
}));
