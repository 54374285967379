import React from 'react';
import { LocalAudioTrack } from 'twilio-video';
import { VolumeIndicator } from '@zeals/shared-components';
import MicIcon from '@material-ui/icons/Mic';

type MicrophoneProps = {
  localAudioTrack: LocalAudioTrack;
};

const Microphone: React.FC<MicrophoneProps> = ({
  localAudioTrack,
}: MicrophoneProps) => {
  return localAudioTrack && localAudioTrack.mediaStreamTrack ? (
    <VolumeIndicator track={localAudioTrack} id={localAudioTrack.name} />
  ) : (
    <MicIcon />
  );
};

export default Microphone;
