import apisauce from 'apisauce';
import transformResponse from '@zeals/shared-utils';
import {
  GetCurrentUserResponse,
  GoogleLoginRequest,
  GoogleLoginResponse,
  UpdateAdminRequest,
  UpdateAdminResponse,
  GetEnduserRequset,
  GetEnduserResponse,
} from '@zeals/shared-types/build/api/admin';

import {
  GetRoomResponse,
  CreateRoomRequest,
  CreateRoomResponse,
} from '@zeals/shared-types/build/api/matching';

import { ListRoomsResponse } from '@zeals/shared-types/build/matching';

import { api as apiTypes } from '@zeals/shared-types';

import { ApiResponseWrapper } from '@zeals/shared-types/build/api/error';

import config from '../../config';
import { rootStore } from '../../stores/rootStore';

const api = apisauce.create({
  baseURL: config.serverUrl,
  headers: {
    Accept: 'application/json;charset=UTF-8',
    'Content-Type': 'application/json',
  },
});

api.addRequestTransform((request) => {
  request.headers.Authorization = rootStore.authStore.accessToken;
});

api.addResponseTransform((response) => {
  if (response.status === apiTypes.error.HTTPStatusCode.Forbidden) {
    rootStore.authStore.reset();
  }
});

export default {
  closeRoom: (id: string): Promise<ApiResponseWrapper<void>> => {
    return transformResponse<void>(api.put, {
      url: `/admin/matching/rooms/${id}/close`,
    });
  },
  createRoom: (
    request: CreateRoomRequest
  ): Promise<ApiResponseWrapper<CreateRoomResponse>> => {
    return transformResponse<CreateRoomResponse>(api.post, {
      payload: request,
      url: '/admin/matching/rooms',
    });
  },
  getCurrentUser: (): Promise<ApiResponseWrapper<GetCurrentUserResponse>> => {
    return transformResponse<GetCurrentUserResponse>(api.get, {
      url: '/admin/admins/me',
    });
  },
  getEnduser: (
    request: GetEnduserRequset
  ): Promise<ApiResponseWrapper<GetEnduserResponse>> => {
    return transformResponse<GetEnduserResponse>(api.get, {
      url: `/admin/endusers/${request.id}`,
    });
  },
  getRoom: (id: string): Promise<ApiResponseWrapper<GetRoomResponse>> => {
    return transformResponse<GetRoomResponse>(api.get, {
      url: `/admin/matching/rooms/${id}`,
    });
  },
  listMyRooms: (): Promise<ApiResponseWrapper<ListRoomsResponse>> => {
    return transformResponse<ListRoomsResponse>(api.get, {
      url: '/admin/matching/rooms/me',
    });
  },
  loginWithGoogle: (
    request: GoogleLoginRequest
  ): Promise<ApiResponseWrapper<GoogleLoginResponse>> => {
    return transformResponse<GoogleLoginResponse>(api.post, {
      payload: request,
      url: '/admin/auth/login/google',
    });
  },
  // temporary placing here, later on see if we want to split it
  updateAdmin: (
    request: UpdateAdminRequest
  ): Promise<ApiResponseWrapper<UpdateAdminResponse>> => {
    return transformResponse<UpdateAdminResponse>(api.put, {
      payload: request,
      url: '/admin/admins',
    });
  },
};
