"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const styles_1 = require("@material-ui/core/styles");
exports.default = styles_1.makeStyles(() => {
    return {
        airplaneContainer: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'center',
            position: 'relative',
            width: '100%',
        },
        airplane: {
            animation: '$move 3s infinite',
            opacity: 0,
            position: 'absolute',
            transform: 'rotate(45deg)',
        },
        '@keyframes move': {
            '0%': {
                left: '0%',
                opacity: 0,
            },
            '50%': {
                left: '50%',
                opacity: 1,
            },
            '100%': {
                left: '75%',
                opacity: 0,
            },
        },
    };
});
