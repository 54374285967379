"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const styles_1 = require("@material-ui/core/styles");
exports.default = styles_1.makeStyles((theme) => {
    const spaceMap = {
        xs: theme.spacing(0),
        s: theme.spacing(1),
        m: theme.spacing(2),
        l: theme.spacing(3),
        xl: theme.spacing(4),
    };
    return {
        spacer: {
            height: (props) => spaceMap[props.size],
        },
    };
});
