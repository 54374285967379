import React, { useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { CompanyLogo, Copyright } from '@zeals/shared-components';

import { useVendorConfig } from '../../config';

import { useStores } from '../../stores';
import { Routes } from '../../navigations/Routes';

import GoogleLoginButton from './GoogleLoginButton';

import useStyles from './styles';

const LoginPage: React.FC = () => {
  const { t: translate } = useTranslation();
  const { authStore } = useStores();
  const classes = useStyles();
  const history = useHistory();
  const vendorConfig = useVendorConfig();

  const [loading, setLoading] = useState(false);

  return (
    <Grid
      className={classes.container}
      container
      spacing={4}
      direction="column"
      justify="center"
      alignItems="center"
    >
      <Grid
        alignItems="center"
        container
        direction="column"
        justify="center"
        item
        className={classes.cardContainer}
        spacing={5}
      >
        <Grid item>
          <CompanyLogo company="his" scale={2} />
        </Grid>
        <Grid item>
          <Typography className={classes.title}>
            {translate('page.login.title')}
          </Typography>
        </Grid>
        <Grid item>
          <GoogleLoginButton
            loading={loading}
            onClick={() => {
              setLoading(true);
            }}
            onCancel={() => setLoading(false)}
            onLogin={(user) => {
              Promise.resolve().then(async () => {
                const oauthToken = await user.getIdToken();
                await authStore.loginWithGoogle({
                  domain: vendorConfig.domain,
                  oauthToken,
                });

                // push this to shift management page after login
                setLoading(false);
                history.push(Routes.ShiftManagement);
              });
            }}
          />
        </Grid>
      </Grid>
      <Copyright reserveFill={false} isPositionAbsolute />
    </Grid>
  );
};

export default LoginPage;
