import { createMuiTheme } from '@material-ui/core/styles';
import { rgba } from '@zeals/shared-utils';

export default createMuiTheme({
  overrides: {
    MuiInputLabel: {
      outlined: {
        color: '#000',
      },
    },
  },
  palette: {
    type: 'light',
    primary: {
      main: '#4285F4',
      light: '#0057B8',
    },
    secondary: {
      main: '#1EC18D',
    },
    error: {
      main: '#E5004B',
    },
    success: {
      main: '#1EC18D',
    },
    text: {
      primary: '#000',
      secondary: '#fff',
    },
    warning: {
      main: '#FFCA28',
    },
  },
  spacing: [2, 4, 8, 16, 32, 64, 128, 256, 512, 1024, 2048],
  typography: {
    fontFamily: 'Noto Sans JP',
    h6: {
      fontWeight: 'bold',
    },
    subtitle1: {
      fontWeight: 'bold',
    },
    subtitle2: {
      color: rgba('#000', 0.2),
      fontSize: '0.75em',
      fontWeight: 'bold',
    },
    button: {
      fontWeight: 800,
      fontSize: 14,
    },
  },
});
