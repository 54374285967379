"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const ScrollTop = ({ children }) => {
    const history = react_router_dom_1.useHistory();
    react_1.useEffect(() => {
        const dispose = history.listen(() => {
            window.scrollTo(0, 0);
        });
        return () => {
            dispose();
        };
    });
    return children;
};
exports.default = ScrollTop;
