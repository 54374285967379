import { action, observable } from 'mobx';
import { persist } from 'mobx-persist';

import { RootStore } from './rootStore';

import { AvailableLanguages } from '../i18n';

class SettingStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @persist @observable language: AvailableLanguages = 'jp';

  @persist @observable selectedVideoDevice = '';

  @persist @observable selectedAudioDevice = '';

  @action setSelectedVideoDevice = (deviceId: string): void => {
    this.selectedVideoDevice = deviceId;
  };

  @action setSelectedAudioDevice = (deviceId: string): void => {
    this.selectedAudioDevice = deviceId;
  };
}

export default SettingStore;
