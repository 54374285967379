import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import {
  Dialog,
  ErrorMessage,
  HotAirBalloonSVG,
} from '@zeals/shared-components';
import useStyles from './styles';

const UnknownErrorPage: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const hotAirBalloonSVG = <HotAirBalloonSVG />;
  const { t: translate } = useTranslation();

  return (
    <div className={classes.container}>
      <div className={classes.dialogContainer}>
        <Dialog
          header={{
            className: classes.header,
          }}
          body={{
            children: (
              <ErrorMessage
                svg={hotAirBalloonSVG}
                title={translate('common.errors.unknown.title')}
              />
            ),
          }}
          buttons={{
            children: (
              <div className={classes.buttonsContainer}>
                <Button
                  id="unknown-error-confirm"
                  className={classes.button}
                  color="primary"
                  fullWidth
                  onClick={() => history.go(0)}
                  variant="outlined"
                >
                  {translate('common.errors.unknown.button')}
                </Button>
              </div>
            ),
          }}
        />
      </div>
    </div>
  );
};

export default UnknownErrorPage;
