"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
function useEvent(type, listener, passive = false) {
    react_1.useEffect(() => {
        // initiate the event handler
        window.addEventListener(type, listener, passive);
        // this will clean up the event every time the component is re-rendered
        return function cleanup() {
            window.removeEventListener(type, listener);
        };
    });
}
exports.default = useEvent;
