"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const styles_1 = require("@material-ui/core/styles");
exports.default = styles_1.makeStyles((theme) => ({
    container: {
        color: theme.palette.common.white,
        maxWidth: '600px',
        height: '100%',
        width: '100%',
        '& >.header': {
            alignItems: 'center',
            backgroundColor: theme.palette.primary.light,
            borderTopRightRadius: '24px 24px',
            borderTopLeftRadius: '24px 24px',
            display: 'flex',
            justifyContent: 'center',
            padding: theme.spacing(3),
            '& >.logo': {
                maxHeight: '40px',
            },
            width: '100%',
        },
        '& >.body': {
            backgroundColor: theme.palette.common.white,
            color: theme.palette.common.black,
            height: '100%',
            padding: theme.spacing(3),
            paddingTop: theme.spacing(4),
            position: 'relative',
            rowGap: `${theme.spacing(2)}px`,
            width: '100%',
        },
        '& >.buttons': {
            alignItems: 'center',
            backgroundColor: theme.palette.common.white,
            borderBottomRightRadius: '24px 24px',
            borderBottomLeftRadius: '24px 24px',
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
        },
    },
}));
