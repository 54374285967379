"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const HangupSVG = ({ className }) => {
    return (React.createElement("svg", { className: className, fill: "none", height: 28, viewBox: "0 0 74 28", width: 74, xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M72.741 12.304C62.419 4.692 50.061.667 37 .667c-13.06 0-25.419 4.025-35.741 11.637a2.25 2.25 0 00-.905 1.509c-.08.602.085 1.208.462 1.686L9.44 26.435a2.35 2.35 0 001.56.875 2.392 2.392 0 001.744-.446 40.53 40.53 0 0112.066-6.113c1.235-.388 1.914-1.669 1.518-2.867l-2.894-8.702c8.771-2.161 18.354-2.166 27.13 0l-2.894 8.702c-.4 1.198.283 2.48 1.518 2.867a40.698 40.698 0 0112.066 6.113c.414.3.914.47 1.432.47.104 0 .208-.01.312-.024a2.372 2.372 0 001.56-.87l8.625-10.94c.377-.48.547-1.09.462-1.687a2.25 2.25 0 00-.905-1.509z", fill: "#E5004B" })));
};
exports.default = HangupSVG;
