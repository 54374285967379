import { makeStyles } from '@material-ui/core/styles';
import { isIOS } from 'react-device-detect';

export default makeStyles((theme) => ({
  container: {
    height: '100%',
    maxHeight: '100%',
    maxWidth: '100%',
    minHeight: isIOS ? '-webkit-fill-available' : '100vh',
    overflow: 'hidden',
  },
  endCallButton: {
    background: theme.palette.error.main,
    boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.2)',
    borderRadius: '50%',
    color: theme.palette.common.white,
    height: '40px',
    width: '40px',
    '&:hover': {
      color: theme.palette.error.main,
    },
  },
  overlay: {
    height: '100%',
    width: '100%',
    zIndex: 1,
  },
  loadingText: {
    flexGrow: 1,
  },
  topBar: {
    display: 'flex',
    padding: 20,
    width: '100%',
  },
}));
