import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  buttonContainer: {
    alignItems: 'center',
    display: 'flex',
    padding: theme.spacing(4),
    justifyContent: 'center',
  },
  dialogRoot: {
    backgroundColor: 'transparent',
  },
  paper: {
    borderRadius: '30px',
    boxShadow: '0px 5px 20px rgba(0, 87, 184, 0.1)',
    minHeight: '541px',
    minWidth: '463px',
    width: '100%',
  },
  saveButton: {
    background: theme.palette.secondary.main,
    color: theme.palette.common.white,
    width: '440px',
    '&:hover': {
      background: '#00A496',
      color: theme.palette.common.white,
    },
  },
  titleContainer: {
    backgroundColor: theme.palette.primary.light,
    height: '60px',
    padding: theme.spacing(2),
    '& >.logo': {
      maxHeight: '40px',
    },
  },
  videoSettingsContainer: {
    height: 'fit-content',
    marginLeft: '80px',
    marginRight: '80px',
    marginTop: '40px',
    '& >.videoSettingsTitle': {
      marginBottom: '52px',
      '& >.font': {
        color: '#0057B8',
        fontSize: '1.25rem',
        fontWeight: '700',
      },
    },
  },
}));
