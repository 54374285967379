import { ENVS, EnvironmentConfig } from './environments/types';

import develop from './environments/develop';
import staging from './environments/staging';
import production from './environments/production';

import vendors from './vendors';
import { VendorConfig } from './vendors/types';

const envMap: Record<string, EnvironmentConfig> = {
  [ENVS.DEVELOP]: develop,
  [ENVS.STAGING]: staging,
  [ENVS.PRODUCTION]: production,
};

export const useVendorConfig = <T extends VendorConfig = VendorConfig>(): T => {
  const [, key] = window.location.pathname.split('/');

  const vendorConfig = vendors[key];

  if (!vendorConfig) {
    return null;
  }

  return vendorConfig as T;
};

export default envMap[process.env.REACT_APP_ENV] || develop;
