"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const styles_1 = require("@material-ui/core/styles");
exports.default = styles_1.makeStyles((theme) => {
    return {
        container: (props) => {
            return {
                width: '100%',
                position: props.isPositionAbsolute ? 'absolute' : 'relative',
                bottom: 0,
                marginBottom: theme.spacing(2),
                marginTop: theme.spacing(2),
            };
        },
        text: (props) => {
            const ratioByScale = (props.scale * 3) / 4;
            return {
                fontSize: `${ratioByScale}rem`,
                marginRight: theme.spacing(1),
                color: props.reserveFill ? theme.palette.common.white : '#263238',
            };
        },
    };
});
