import { makeStyles } from '@material-ui/core/styles';
import { blue } from '@material-ui/core/colors';

export default makeStyles((theme) => ({
  label: {
    color: blue[900],
    fontSize: '0.75em',
    fontWeight: 400,
    marginBottom: '4px',
  },
  infoText: {
    fontSize: '1em',
    fontWeight: 700,
  },
  userInfoContainer: {
    display: 'inline-flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    height: '50vh',
    width: '100%',
    '& >.infoContainer': {
      display: 'flex',
      margin: `${theme.spacing(3)}px ${theme.spacing(3)}px 0px ${theme.spacing(
        3
      )}px`,
      flexBasis: 'auto',
      '& >.personalInfoRow': {
        display: 'inline-flex',
        gap: '20px',
        '& >.name': {
          display: 'block',
        },
        '& >.furigana': {
          display: 'block',
        },
        '& >.phone': {
          display: 'block',
        },
        '& >.origin': {
          display: 'block',
        },
        '& >.destination': {
          display: 'block',
        },
      },
    },
    '& >.memoContainer': {
      display: 'flex',
      height: '100%',
      minHeight: '123px',
      maxHeight: '35vh',
      margin: theme.spacing(3),
      flex: '1 0 90%',
      '& >.memoText': {
        width: '100%',
        '& >.memoTextField': {
          height: '100%',
          width: '100%',
        },
      },
    },
  },
  memoTextFieldInput: {
    color: theme.palette.common.black,
    fontSize: '0.875em',
  },
  memoTextFieldInputOutline: {
    borderRadius: '10px',
    boxSizing: 'border-box',
  },
  memoTextFieldInputRoot: {
    color: theme.palette.common.black,
  },
}));
