import React, { useEffect } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import { observer } from 'mobx-react';
import firebase from 'firebase/app';

import { ErrorBoundry, PageViewMonitor } from '@zeals/shared-components';

import Route from './Route';
import { Routes } from './Routes';
import { useVendorConfig } from '../config';

// Pages
import ChatVideoPage from '../pages/ChatVideoPage';
import ChatVideoLogPage from '../pages/ChatVideoLogPage';
import ShiftManagementPage from '../pages/ShiftManagementPage';
import LoginPage from '../pages/LoginPage';
import UnknownErrorPage from '../pages/errors/UnknownError';

const Router: React.FC = () => {
  const vendorConfig = useVendorConfig();

  useEffect(() => {
    if (!vendorConfig) {
      return;
    }

    firebase.analytics().setUserProperties({
      app: 'admin',
      vendor: vendorConfig.domain,
    });
  }, [vendorConfig]);

  if (!vendorConfig) {
    // TODO: add fallback?
    return null;
  }

  return (
    <BrowserRouter basename={`/${vendorConfig.domain}`}>
      <PageViewMonitor>
        <ErrorBoundry fallback={<UnknownErrorPage />}>
          <Switch>
            <Route path={Routes.Login} privateRoute={false}>
              <LoginPage />
            </Route>

            <Route exact path={Routes.ChatVideo}>
              <ChatVideoPage />
            </Route>

            <Route path={Routes.ChatVideoLog}>
              <ChatVideoLogPage />
            </Route>

            <Route path={Routes.ShiftManagement}>
              <ShiftManagementPage />
            </Route>
          </Switch>
        </ErrorBoundry>
      </PageViewMonitor>
    </BrowserRouter>
  );
};

export default observer(Router);
