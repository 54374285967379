import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@material-ui/core';

import { MessageInstance } from '@zeals/shared-components';
import { matching } from '@zeals/shared-types';

import Chat from '../../components/ChatComponent';
import EnduserProfile from '../../components/EnduserProfile';
import ChatVideoLogLoadingBlock from './loading/ChatVideoLogLoadingBlock';

import useStyles from './styles';
import { useStores } from '../../stores';

type ChatVideoLogPageParams = {
  roomId: string;
};

const ChatVideoLogPage: React.FC = () => {
  const classes = useStyles();
  const { t: translate } = useTranslation();
  const {
    matchingStore,
    reservationStore,
    conversationStore,
    videoStore,
  } = useStores();
  const { roomId } = useParams<ChatVideoLogPageParams>();
  const [messages, setMessages] = useState<MessageInstance[]>([]);
  const [inited, setInited] = useState(false);
  const [videoUri, setVideoUri] = useState('');

  useEffect(() => {
    Promise.all([
      matchingStore.getRoom(roomId),
      reservationStore.listReservations({
        filter: {
          roomId,
        },
      }),
    ])
      .then(async ([, reservationResponse]) => {
        if (matchingStore.room.status !== matching.RoomStatus.CLOSED) {
          // TODO: add proper UI for this
          alert('room is still active');
          return;
        }

        if (
          reservationResponse &&
          reservationResponse.reservations &&
          reservationResponse.reservations.length > 0
        ) {
          reservationStore.setMemo(
            reservationResponse.reservations[0].additionalFields.memo
          );
        }

        const [messagesResponse, videoSessionResponse] = await Promise.all([
          conversationStore.listConversationMessages({
            conversationId: matchingStore.room.conversationId,
          }),
          videoStore.getVideoSession({
            id: matchingStore.room.videoSessionId,
          }),
        ]);
        setMessages(
          messagesResponse.data.messages.map((msg) => {
            return {
              ...msg,
              direction:
                matchingStore.room.adminId === msg.author
                  ? 'outgoing'
                  : 'incoming',
            };
          })
        );
        setVideoUri(videoSessionResponse.data.archiveUrl);
        setInited(true);
      })
      .catch((err) => console.error(err));
  }, [matchingStore, videoStore, conversationStore, reservationStore, roomId]);

  if (!inited) {
    return <ChatVideoLogLoadingBlock />;
  }

  return (
    <div className={classes.rootContainer}>
      <div className="chat">
        <div className="chatInfo">
          <EnduserProfile
            disableMemoEdit
            enduser={matchingStore.enduser}
            memo={reservationStore.memo}
          />
        </div>
        <div className="chatConversation">
          <Chat
            messages={messages.map((message) => {
              return {
                ...message,
                author:
                  message.direction === 'outgoing'
                    ? translate('component.chat.operatorName')
                    : matchingStore.enduser.name,
              };
            })}
          />
        </div>
      </div>
      <div className="video">
        <div className="webCamera">
          {videoUri ? (
            <video
              className={classes.fullWidthCenter}
              src={videoUri}
              controls
            />
          ) : (
            <div className={classes.fullWidthCenter}>
              <CircularProgress />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatVideoLogPage;
