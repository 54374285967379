"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const styles_1 = require("@material-ui/core/styles");
exports.default = styles_1.makeStyles((theme) => {
    const colorMap = {
        primary: theme.palette.primary.main,
        primaryLight: theme.palette.primary.light,
        secondary: theme.palette.secondary.main,
        secondaryLight: theme.palette.secondary.light,
        default: '',
    };
    const textColor = {
        primary: theme.palette.common.white,
        primaryLight: theme.palette.common.white,
        secondary: theme.palette.common.white,
        secondaryLight: theme.palette.common.white,
        default: theme.palette.error.main,
    };
    return {
        button: (props) => {
            const variant = props.variant === 'rounded'
                ? {
                    borderRadius: '32px',
                }
                : {};
            return Object.assign({ width: '100%', backgroundColor: props.variant !== 'text' ? colorMap[props.color] : 'transparent', color: props.variant !== 'text'
                    ? textColor[props.color]
                    : colorMap[props.color], padding: theme.spacing(2) * 1.5 }, variant);
        },
        label: {
            // prevent span inside a button trigger events
            pointerEvents: 'none',
        },
    };
});
