"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const styles_1 = require("@material-ui/core/styles");
exports.default = styles_1.makeStyles((theme) => ({
    link: {
        color: (props) => props.direction === 'outgoing'
            ? theme.palette.common.white
            : theme.palette.common.black,
        '&:hover': {
            color: (props) => props.direction === 'outgoing'
                ? theme.palette.common.white
                : theme.palette.common.black,
            cursor: 'pointer',
        },
        '&:visited': {
            color: (props) => props.direction === 'incoming'
                ? theme.palette.grey[600]
                : theme.palette.grey[100],
        },
    },
    messageContainer: {
        alignSelf: (props) => props.direction === 'incoming' ? 'flex-start' : 'flex-end',
        display: 'grid',
        gridTemplateColumns: 'minmax(0, max-content) minmax(0, 1fr) minmax(0, max-content)',
        gridTemplateRows: 'minmax(0, max-content) minmax(0, 1fr)',
        '& >.info': {
            display: 'flex',
            gridColumn: 2,
            gridRow: 1,
            justifyContent: (props) => props.direction === 'outgoing' ? 'flex-end' : 'flex-start',
            '& >.name': {
                '&> .font': {
                    color: (props) => props.theme === 'dark'
                        ? theme.palette.common.white
                        : theme.palette.common.black,
                    fontSize: '0.75em',
                    fontWeight: 400,
                    marginBottom: theme.spacing(1),
                    marginRight: theme.spacing(1),
                },
            },
        },
        '& >.message': {
            background: (props) => props.direction === 'incoming'
                ? theme.palette.grey[100]
                : theme.palette.primary.main,
            border: '1px solid rgba(0,82,180,0.2)',
            borderRadius: '10px',
            color: (props) => props.direction === 'incoming'
                ? theme.palette.common.black
                : theme.palette.common.white,
            gridColumn: 2,
            gridRow: 2,
            maxWidth: '250px',
            padding: `${theme.spacing(1)}px ${theme.spacing(3)}px ${theme.spacing(1)}px ${theme.spacing(3)}px`,
            '& >.font': {
                fontSize: '0.875em',
            },
        },
        '& >.timestamp': {
            alignSelf: 'end',
            gridColumn: (props) => (props.direction === 'incoming' ? 3 : 1),
            gridRow: 2,
            '&> .font': {
                color: (props) => props.theme === 'dark'
                    ? theme.palette.common.white
                    : theme.palette.grey[600],
                fontSize: '0.5em',
                fontWeight: 400,
                marginBottom: theme.spacing(1),
                marginLeft: (props) => (props.direction === 'incoming' ? '4px' : 0),
                marginRight: (props) => (props.direction === 'incoming' ? 0 : '4px'),
            },
        },
    },
}));
