import React, { useEffect, useState, useCallback } from 'react';
import firebase from 'firebase/app';
import { Dialog } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { BoardingPass, CompanyLogo } from '@zeals/shared-components';
import { MatchingNotification } from '@zeals/shared-types/build/notification';
import { EnduserInstance } from '@zeals/shared-types/build/enduser';
import { matching } from '@zeals/shared-types';

import useStyles from './styles';
import { useStores } from '../../../stores';

// workaround since configurating import statement work on non ts module is troublesome
const telephoneRingSound = require('../../../resources/audio/telephone-ring.mp3')
  .default;

const ringBell = new Audio(telephoneRingSound);

const BoardingPassDialogBlock: React.FC = () => {
  const classes = useStyles();
  const { t: translate } = useTranslation();
  const { authStore, enduserStore, matchingStore } = useStores();
  const history = useHistory();

  const [incomingEnduser, setIncomingEnduser] = useState<EnduserInstance>();
  const [roomId, setRoomId] = useState('');

  const showDialog = useCallback(
    async (activeRoomId: string, enduserId: string): Promise<void> => {
      const endUserResponse = await enduserStore.getEnduser(enduserId);
      const { user } = endUserResponse.data;
      setIncomingEnduser(user);
      setRoomId(activeRoomId);
      ringBell.loop = true;
      ringBell.volume = 1;
      ringBell.play();
    },
    [enduserStore]
  );

  useEffect(() => {
    const dispose = firebase.messaging().onMessage((msg) => {
      const data = msg.data as MatchingNotification;

      const logNotification = async () => {
        let notificationPermission = 'unknown';
        if (navigator && navigator.permissions) {
          // https://w3c.github.io/permissions/#notifications
          const res = await navigator.permissions.query({
            name: 'notifications',
          });
          notificationPermission = res.state;
        }
        // TODO: temporary using warn since sentry is not catching info log
        console.warn('client received data notification', {
          adminId: authStore.user.id,
          userId: data.userId,
          roomId: data.roomId,
          notificationPermission,
        });
      };

      logNotification();

      showDialog(data.roomId, data.userId);
    });

    return () => {
      dispose();
      ringBell.volume = 0;
      ringBell.pause();
    };
  }, [authStore, enduserStore, showDialog]);

  useEffect(() => {
    if (
      matchingStore.room &&
      matchingStore.room.status === matching.RoomStatus.IN_PROGRESS &&
      matchingStore.room.enduserId
    ) {
      showDialog(matchingStore.room.id, matchingStore.room.enduserId);
    }
  }, [matchingStore.room, showDialog]);

  if (!roomId || !incomingEnduser) {
    return null;
  }

  return (
    <Dialog
      classes={{
        paper: classes.paper,
      }}
      disableBackdropClick
      open
    >
      <BoardingPass
        translate={translate}
        alertText={translate(
          'page.shiftManagement.boardingPass.customerIsWaiting'
        )}
        closeButtonText={translate(
          'page.shiftManagement.boardingPass.closeButton'
        )}
        destination={translate(
          `common.regions.${incomingEnduser.metadata.destination}`
        )}
        furigana={incomingEnduser.metadata.furigana}
        logo={<CompanyLogo company="his" reserveFill />}
        name={incomingEnduser.name}
        phone={incomingEnduser.phone}
        onConfirm={() => {
          history.push(`/rooms/${roomId}`);
        }}
        origin={translate(`common.regions.${incomingEnduser.metadata.region}`)}
      />
    </Dialog>
  );
};

export default BoardingPassDialogBlock;
