"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ErrorBoundry = void 0;
const react_1 = require("react");
class ErrorBoundry extends react_1.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
        };
    }
    componentDidCatch(error, errorInfo) {
        console.error({ error, errorInfo });
    }
    static getDerivedStateFromError() {
        return {
            hasError: true,
        };
    }
    render() {
        if (this.state.hasError) {
            return this.props.fallback;
        }
        return this.props.children;
    }
}
exports.ErrorBoundry = ErrorBoundry;
exports.default = ErrorBoundry;
