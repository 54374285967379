import { observable, action, runInAction } from 'mobx';

import { ApiResponseWrapper } from '@zeals/shared-types/build/api/error';
import { GetEnduserResponse } from '@zeals/shared-types/build/api/admin';
import { RootStore } from './rootStore';

import adminApi from '../services/api/adminApi';

class EnduserStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable fetching = false;

  @action getEnduser = async (
    id: string
  ): Promise<ApiResponseWrapper<GetEnduserResponse>> => {
    if (this.fetching) return null;

    this.fetching = true;

    try {
      const response = await adminApi.getEnduser({ id });
      if (response.error) {
        const { message } = response.error;
        throw new Error(message);
      }

      return response;
    } finally {
      runInAction(() => {
        this.fetching = false;
      });
    }
  };
}

export default EnduserStore;
