import { ConnectOptions } from 'twilio-video';

export enum ENVS {
  DEVELOP = 'develop',
  STAGING = 'staging',
  PRODUCTION = 'production',
}

export type EnvironmentConfig = {
  serverUrl: string;

  environment: ENVS;

  firebaseConfig: {
    apiKey: string;
    authDomain: string;
    databaseURL: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
    measurementId: string;
  };

  tagManagerContainerId: string;

  sentryDst: string;

  sentryEnabled: boolean;

  twilioConnectOptions: ConnectOptions;
};
