"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useParticipantTracks = exports.useAudioTrack = exports.useVideoTrack = void 0;
const react_1 = require("react");
exports.useVideoTrack = (publication) => {
    const [track, setTrack] = react_1.useState(publication && publication.track);
    react_1.useEffect(() => {
        // Reset the track when the 'publication' variable changes.
        setTrack(publication && publication.track);
        if (!publication) {
            return () => { };
        }
        const removeTrack = () => setTrack(null);
        publication.on('subscribed', setTrack);
        publication.on('unsubscribed', removeTrack);
        return () => {
            publication.off('subscribed', setTrack);
            publication.off('unsubscribed', removeTrack);
        };
    }, [publication]);
    return track;
};
exports.useAudioTrack = (publication) => {
    const [track, setTrack] = react_1.useState(publication && publication.track);
    react_1.useEffect(() => {
        // Reset the track when the 'publication' variable changes.
        setTrack(publication && publication.track);
        if (!publication) {
            return () => { };
        }
        const removeTrack = () => setTrack(null);
        publication.on('subscribed', setTrack);
        publication.on('unsubscribed', removeTrack);
        return () => {
            publication.off('subscribed', setTrack);
            publication.off('unsubscribed', removeTrack);
        };
    }, [publication]);
    return track;
};
exports.useParticipantTracks = (participant) => {
    const [publications, setPublications] = react_1.useState([]);
    const videoTrack = exports.useVideoTrack(publications.find((publication) => publication.kind === 'video'));
    const audioTrack = exports.useAudioTrack(publications.find((publication) => publication.kind === 'audio'));
    react_1.useEffect(() => {
        if (!participant) {
            return () => { };
        }
        // Reset the publications when the 'participant' variable changes.
        setPublications([
            ...participant.videoTracks.values(),
            ...participant.audioTracks.values(),
        ]);
        const publicationAdded = (publication) => {
            if (publication.kind === 'video' &&
                publication.track &&
                publication.track.mediaStreamTrack &&
                publication.track.mediaStreamTrack.readyState === 'ended') {
                // since there is no trackUnpublished published by the library
                // either local track got published or unpublished is using same event (maybe a bug?)
                // for now as a workaround we still check the mediatrack status
                setPublications((prevPublications) => prevPublications.filter((p) => p.trackName !== publication.trackName));
                return;
            }
            setPublications((prevPublications) => [...prevPublications, publication]);
        };
        const publicationRemoved = (publication) => {
            setPublications((prevPublications) => prevPublications.filter((p) => p.trackName !== publication.trackName));
        };
        participant.on('trackPublished', publicationAdded);
        participant.on('trackUnpublished', publicationRemoved);
        // temporary workaround since event in publication is not working
        // monitoring it in participant level
        // issue: https://github.com/twilio/twilio-video.js/issues/1202
        participant.on('trackEnabled', publicationAdded);
        participant.on('trackDisabled', publicationRemoved);
        return () => {
            participant.off('trackPublished', publicationAdded);
            participant.off('trackUnpublished', publicationRemoved);
            participant.off('trackEnabled', publicationAdded);
            participant.off('trackDisabled', publicationRemoved);
        };
    }, [participant]);
    return [videoTrack, audioTrack];
};
