"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function isError(response) {
    var _a;
    return ((_a = response) === null || _a === void 0 ? void 0 : _a.error) !== undefined;
}
async function transformResponse(request, params, axiosConfig) {
    const originalResponse = await request(params.url, params.payload, axiosConfig);
    if (isError(originalResponse === null || originalResponse === void 0 ? void 0 : originalResponse.data)) {
        console.error(originalResponse.originalError);
        const { message, status } = originalResponse.data.error;
        return {
            status: originalResponse.status,
            data: undefined,
            error: { message, status },
        };
    }
    return {
        status: originalResponse.status,
        data: originalResponse.data,
        error: undefined,
    };
}
exports.default = transformResponse;
