import { makeStyles, Theme } from '@material-ui/core/styles';
import { lightBlue } from '@material-ui/core/colors';

export type ChatProps = {
  inputEnabled: boolean;
};

export default makeStyles<Theme, ChatProps>((theme) => ({
  chatContainer: {
    display: 'grid',
    gridTemplateRows: (props) =>
      props.inputEnabled ? 'minmax(0, 1fr) minmax(0, 102px)' : 'minmax(0, 1fr)',
    height: '100%',
    maxHeight: '100%',
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
  },
  chatDialog: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column-reverse',
    gridRow: 1,
    justifyItems: 'center',
    padding: '10px 0px 10px 0px',
    position: 'relative',
  },
  inputContainer: {
    alignSelf: 'center',
    border: '1px solid rgba(0, 0, 0, 0.2)',
    boxSizing: 'border-box',
    borderRadius: '60px',
    display: 'grid',
    height: '56px',
    justifySelf: 'center',
    gridRow: 2,
    gridTemplateColumns: 'minmax(0, 1fr) minmax(0, auto)',
    width: '95%',
  },
  input: {
    color: theme.palette.common.black,
    fontFamily: 'Noto Sans JP',
    fontSize: '0.875em',
    fontStyle: 'normal',
    fontWeight: 'normal',
    gridColumn: 1,
    justifySelf: 'start',
    marginLeft: '24px',
    width: '100%',
    '&::placeholder': {
      color: lightBlue[800],
      fontWeight: '400',
    },
  },
  sendButton: {
    color: theme.palette.primary.main,
    fontWeight: 700,
    gridColumn: 2,
    justifySelf: 'end',
    marginRight: '24px',
  },
}));
