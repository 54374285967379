import { observable, action } from 'mobx';
import { v4 as uuid } from 'uuid';

import { RootStore } from './rootStore';
import { IToast } from './types';

class NotificationStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable toasts: IToast[] = [];

  @action addToast = (toast: IToast): void => {
    this.toasts = [
      ...this.toasts,
      {
        id: uuid(),
        ...toast,
      },
    ];
  };

  @action removeToast = (toast: IToast): void => {
    this.toasts = this.toasts.filter((t) => t.id !== toast.id);
  };
}

export default NotificationStore;
