import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import * as Sentry from '@sentry/react';

import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import {
  FormControl,
  MenuItem,
  Select,
  Typography,
  Divider,
  Link,
} from '@material-ui/core';

import { Button, CompanyLogo, Copyright } from '@zeals/shared-components';
import { matching } from '@zeals/shared-types';

import DeviceSettingPanel from '../../components/DeviceSettingPanel';
import BoardingPassDialogBlock from './boardingPass/BoardingPassDialogBlock';

import useStyles from './styles';
import { useStores } from '../../stores';

import { useVendorConfig } from '../../config';
import { HISVendorConfig } from '../../config/vendors/his';

const ShiftManagementPage: React.FC = () => {
  const { t: translate } = useTranslation();
  const { matchingStore, authStore, settingStore } = useStores();
  const vendorConfig = useVendorConfig<HISVendorConfig>();

  const [roomFetched, setRoomFetched] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState(
    (authStore.user &&
      authStore.user.metadata &&
      authStore.user.metadata.region) ||
      vendorConfig.REGIONS.HOKKAIDO
  );

  const classes = useStyles({
    vacancyOpening: !!matchingStore.room,
  });

  useEffect(() => {
    Promise.resolve().then(async () => {
      const rooms = await matchingStore.listMyRooms();
      if (rooms.length > 0) {
        // only take first one
        const room = rooms[0];
        matchingStore.setRoom(room);
        setSelectedRegion(room.labels.region);
      }

      setRoomFetched(true);
    });
  }, [matchingStore]);

  useEffect(() => {
    const beforeUnloadHandler = (e: BeforeUnloadEvent) => {
      if (!matchingStore.room) {
        return;
      }

      // Cancel the event
      e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
      // Chrome requires returnValue to be set
      // Custom message is deprecated so it's not possible to set custom message now
      e.returnValue = '';
    };

    window.addEventListener('beforeunload', beforeUnloadHandler);

    return () => {
      window.removeEventListener('beforeunload', beforeUnloadHandler);
    };
  }, [matchingStore.room]);

  Sentry.setUser({
    id: authStore.user.id,
    username: authStore.user.name,
    email: authStore.user.email,
    ip_address: '{{auto}}',
  });
  Sentry.setTag('region', authStore.user.metadata.region);

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div>
          <CompanyLogo company="his" scale={2} />
        </div>
        <div>
          <Typography variant="h5">
            {translate('page.shiftManagement.window.title')}
          </Typography>
        </div>
        <div>
          <Link
            className={classes.link}
            id="logout"
            onClick={() => {
              authStore.reset();
            }}
          >
            {translate('page.shiftManagement.content.buttons.logout')}
          </Link>
        </div>
      </div>
      <Divider className={classes.devider} />
      <div className={classes.shiftManagementContainer}>
        <div className="windowContentContainer">
          <div className="bodyTop">
            <div className="titleRow">
              <Typography variant="h5">
                {translate('page.shiftManagement.content.title')}
              </Typography>
            </div>
            <div className="statusAction">
              <div className="nameRow">
                <div className="usernameBlock">
                  <Typography className={classes.label}>
                    {translate('page.shiftManagement.content.usernameLabel')}
                  </Typography>
                  <Typography className="usernameText">
                    {authStore.user.name}
                  </Typography>
                </div>
              </div>
              <div className="statusRow">
                <div className="statusBlock">
                  <Typography className={classes.label}>
                    {translate('page.shiftManagement.content.status.title')}
                  </Typography>
                  <FiberManualRecordIcon className="bullet" />
                  <Typography className="statusText">
                    {matchingStore.room
                      ? translate(
                          'page.shiftManagement.content.status.available'
                        )
                      : translate(
                          'page.shiftManagement.content.status.unavailable'
                        )}
                  </Typography>
                </div>
              </div>
              <div className="shiftStartButtonRow">
                {matchingStore.room ? (
                  <Button
                    id="shift-close"
                    variant="rounded"
                    color="default"
                    disabled={!roomFetched}
                    loading={updating}
                    onClick={() => {
                      setUpdating(true);
                      matchingStore
                        .closeRoom(matchingStore.room.id)
                        .then(() => {
                          matchingStore.setRoom(null);
                          setUpdating(false);
                        });
                    }}
                  >
                    {translate(
                      'page.shiftManagement.content.buttons.changeToUnavailable'
                    )}
                  </Button>
                ) : (
                  <Button
                    id="shift-open"
                    variant="rounded"
                    color="secondary"
                    disabled={!roomFetched || !selectedRegion}
                    loading={updating}
                    onClick={() => {
                      Promise.resolve().then(async () => {
                        setUpdating(true);

                        if (selectedRegion !== authStore.user.metadata.region) {
                          // do not await here to block the process
                          authStore.updateAdmin({
                            metadata: {
                              region: selectedRegion,
                            },
                          });
                        }

                        const room = await matchingStore.createRoom({
                          status: matching.RoomStatus.AWAITING_MATCHING,
                          labels: {
                            region: selectedRegion,
                          },
                        });

                        matchingStore.setRoom(room);
                        setUpdating(false);
                      });
                    }}
                  >
                    {translate(
                      'page.shiftManagement.content.buttons.changeToAvailable'
                    )}
                  </Button>
                )}
              </div>
            </div>
            <div className="areaRow">
              <div className="areaInputBlock">
                <Typography className={classes.label}>
                  {translate('page.shiftManagement.content.areaLabel')}
                </Typography>
                <FormControl
                  className="areaInput"
                  margin="dense"
                  variant="outlined"
                >
                  <Select
                    value={selectedRegion}
                    disabled={!!matchingStore.room}
                    onChange={(event) => {
                      setSelectedRegion(event.target.value as string);
                    }}
                  >
                    {Object.values(vendorConfig.REGIONS).map((region) => {
                      return (
                        <MenuItem key={region} value={region}>
                          {translate(`common.regions.${region}`)}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
          <Divider className={classes.devider} />
          <div className="bodyBottom">
            <div className="videoSettingsTitle">
              <Typography variant="h5" gutterBottom>
                {translate('component.deviceSettingPanel.title')}
              </Typography>
            </div>
            <DeviceSettingPanel
              selectedVideoDevice={settingStore.selectedVideoDevice}
              selectedAudioDevice={settingStore.selectedAudioDevice}
              onVideoDeviceChange={(deviceId) =>
                settingStore.setSelectedVideoDevice(deviceId)
              }
              onAudioDeviceChange={(deviceId) =>
                settingStore.setSelectedAudioDevice(deviceId)
              }
              isModal
            />
          </div>
        </div>
      </div>
      <BoardingPassDialogBlock />
      <Copyright reserveFill={false} isPositionAbsolute={false} />
    </div>
  );
};

export default observer(ShiftManagementPage);
