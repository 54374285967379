import React from 'react';
import MusicVideoIcon from '@material-ui/icons/MusicVideo';

import { IconButton } from '@zeals/shared-components';

import useStyles from './styles';

type OnHoldVideoToggleProps = {
  label: string;
  loading?: boolean;
  onChange: (value: boolean) => void;
  value: boolean;
};

const OnHoldVideoToggle: React.FC<OnHoldVideoToggleProps> = ({
  label,
  loading = false,
  value,
  onChange,
}: OnHoldVideoToggleProps) => {
  const classes = useStyles({ value });
  return (
    <IconButton
      id="on-hold-video-button"
      label={label}
      disabled={loading}
      loading={loading}
      onClick={() => onChange(!value)}
      ariaLabel="on-hold-video"
    >
      <span className={classes.icon}>
        <MusicVideoIcon />
      </span>
    </IconButton>
  );
};

export default OnHoldVideoToggle;
