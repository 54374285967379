"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const styles_1 = require("@material-ui/core/styles");
exports.default = styles_1.makeStyles((theme) => ({
    button: {
        color: '#0057B8',
        border: '1px solid #0057B8',
        borderRadius: '50px',
        height: '40px',
    },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: '8px',
        justifyContent: 'center',
        marginBottom: '40px',
        maxWidth: '289px',
        width: '100%',
        [`${theme.breakpoints.between('xs', 'sm')} and (orientation: landscape)`]: {
            flexWrap: 'nowrap',
            marginBottom: '16px',
        },
        '& >.cancelButton': {
            background: theme.palette.common.white,
            borderRadius: '50px',
            color: '#0057B8',
            fontSize: '0.875em',
            height: '48px',
            maxWidth: '289px',
            minWidth: '56px',
            [`${theme.breakpoints.between('xs', 'sm')} and (orientation: landscape)`]: {
                width: '202px',
            },
            '&:hover': {
                background: theme.palette.grey[100],
            },
        },
        '& >.confirmButton': {
            background: theme.palette.secondary.main,
            borderRadius: '50px',
            color: theme.palette.common.white,
            fontSize: '0.875em',
            height: '48px',
            marginBottom: `${theme.spacing(3)}px`,
            maxWidth: '289px',
            minWidth: '56px',
            [`${theme.breakpoints.between('xs', 'sm')} and (orientation: landscape)`]: {
                order: 2,
                width: '202px',
            },
            '&:hover': {
                background: '#00A496',
                color: theme.palette.common.white,
            },
        },
    },
    contentContainer: {
        background: theme.palette.common.white,
        marginTop: '64px',
        [`${theme.breakpoints.between('xs', 'sm')} and (orientation: landscape)`]: {
            marginBottom: '16px',
            marginTop: '16px',
        },
        width: '100%',
    },
    companyLogo: {
        [`${theme.breakpoints.between('xs', 'sm')} and (orientation: landscape)`]: {
            height: '20px',
        },
    },
    dialogContainer: {
        maxWidth: '337px',
        width: '337px',
        [`${theme.breakpoints.between('xs', 'sm')} and (orientation: landscape)`]: {
            maxWidth: '500px',
            width: '500px',
        },
    },
    errorMessageContainer: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        '& >.messageContainer': {
            display: 'flex',
            marginBottom: '40px',
            marginTop: '64px',
            [`${theme.breakpoints.between('xs', 'sm')} and (orientation: landscape)`]: {
                marginBottom: '16px',
                marginTop: '24px',
            },
        },
        '& >.svgContainer': {
            [`${theme.breakpoints.between('xs', 'sm')} and (orientation: landscape)`]: {
                display: 'none',
            },
        },
        width: '100%',
    },
    paper: {
        alignItems: 'center',
        background: theme.palette.common.white,
        borderRadius: '30px',
        display: 'flex',
        justifyContent: 'center',
        maxHeight: 'fit-content',
        maxWidth: 'fit-content',
        width: '100%',
    },
}));
