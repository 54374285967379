import { action, observable } from 'mobx';
import { persist } from 'mobx-persist';

import {
  ListReservationsRequest,
  ListReservationsResponse,
  ReservationInstance,
  UpdateReservationRequest,
} from '@zeals/shared-types/build/reservation';

import { RootStore } from './rootStore';
import reservationApi from '../services/api/reservationApi';

class ReservationStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  // temp
  @persist @observable memo = '';

  @action setMemo = (memo: string): void => {
    this.memo = memo;
  };

  @action listReservations = async (
    request: ListReservationsRequest
  ): Promise<ListReservationsResponse> => {
    const response = await reservationApi.listReservations(request);
    if (response.error) {
      const { message } = response.error;
      throw new Error(message);
    }

    return response.data;
  };

  @action updateReservation = async (
    request: UpdateReservationRequest
  ): Promise<ReservationInstance> => {
    const response = await reservationApi.updateReservation(request);
    if (response.error) {
      const { message } = response.error;
      throw new Error(message);
    }

    return response.data;
  };
}

export default ReservationStore;
