"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.debounce = void 0;
exports.debounce = (func, delay = 200) => {
    let timeout = null;
    return (...args) => {
        if (timeout !== null) {
            clearTimeout(timeout);
        }
        timeout = setTimeout(() => {
            func(...args);
            timeout = null;
        }, delay);
    };
};
