import { makeStyles, Theme } from '@material-ui/core/styles';
import { rgba } from '@zeals/shared-utils';

type ChatRoomVideoBlockStyleProps = {
  revertLocal: boolean;
};

export default makeStyles<Theme, ChatRoomVideoBlockStyleProps>((theme) => ({
  chatRoomVideoContainer: {
    background: '#383838',
    display: 'grid',
    height: '100%',
    gridTemplateRows: 'minmax(0, 1fr) minmax(0, auto) minmax(0, auto)',
    maxHeight: '100%',
    maxWidth: '100%',
    overflow: 'hidden',
    width: '100%',
    '& >.topRow': {
      display: 'inline-flex',
      flexDirection: 'row',
      gridRow: 1,
      position: 'relative',
      zIndex: 1,
      [theme.breakpoints.between('xs', 'xl')]: {
        height: '25vh',
      },
      [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
        height: '35vh',
      },
      '& >.statusBarContainer': {
        display: 'flex',
        flexBasis: '45%',
        flexGrow: 1,
        flexWrap: 'wrap',
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(2),
        width: '100%',
      },
      '& >.localVideoContainer': {
        display: 'flex',
        flexBasis: '55%',
        flexGrow: 1,
        justifyContent: 'flex-end',
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(2),
        position: 'relative',
        width: '100%',
      },
    },
    '& >.bottomRow': {
      display: 'inline-flex',
      height: '100%',
      justifyContent: 'center',
      maxHeight: '92px',
      minHeight: '92px',
      zIndex: 1,
      '& >.screenShare': {
        background: rgba(theme.palette.common.black, 0.8),
        borderRadius: '10px',
        display: 'grid',
        gridTemplateColumns: 'minmax(0, 3fr) minmax(0, 1fr)',
        marginBottom: theme.spacing(3),
        maxHeight: '60px',
        minHeight: '60px',
        maxWidth: '335px',
        minWidth: '200px',
        opacity: 0.8,
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        width: '100%',
        '& >.screenShareText': {
          alignSelf: 'center',
          color: theme.palette.common.white,
          gridColumn: 1,
          justifySelf: 'start',
        },
        '& >.screenShareCancel': {
          alignSelf: 'center',
          gridColumn: 2,
          justifySelf: 'end',
          '& button': {
            color: theme.palette.success.main,
          },
        },
      },
    },
  },
  networkStatusContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    zIndex: 1,
    height: '44px',
    marginTop: 0,
    marginLeft: 'auto',
    marginBottom: theme.spacing(3),
    marginRight: theme.spacing(3),
    width: '30%',
    minWidth: '297px',
    borderRadius: theme.spacing(4),
    backgroundColor: theme.palette.common.black,
    '& >hr': {
      height: '50%',
      backgroundColor: theme.palette.common.white,
    },
  },
  localVideoTag: (props) => {
    const base = {
      borderRadius: '10px',
      height: '100%',
      right: 0,
      top: 0,
      width: 'auto',
    };

    const revertStyle = props.revertLocal
      ? {
          transform: 'rotateY(180deg)',
          '-webkit-transform': 'rotateY(180deg)' /* Safari and Chrome */,
          '-moz-transform': 'rotateY(180deg)' /* Firefox */,
        }
      : {};

    return {
      ...base,
      ...revertStyle,
    };
  },
}));
