"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const AudioTrack = ({ track }) => {
    const audioEl = react_1.useRef();
    react_1.useEffect(() => {
        audioEl.current = track.attach();
        audioEl.current.setAttribute('data-cy-audio-track-name', track.name);
        document.body.appendChild(audioEl.current);
        return () => track.detach().forEach((el) => el.remove());
    }, [track]);
    return null;
};
exports.default = AudioTrack;
