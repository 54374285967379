import { makeStyles } from '@material-ui/core/styles';
import { rgba } from '@zeals/shared-utils';
import { isIOS } from 'react-device-detect';

export default makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.grey[100],
    height: '100%',
    maxHeight: '100%',
    margin: 0,
    maxWidth: '100%',
    minHeight: isIOS ? '-webkit-fill-available' : '100vh',
  },
  cardContainer: {
    alignItems: 'center',
    backgroundColor: theme.palette.common.white,
    borderRadius: '30px',
    boxShadow: `0px 5px 20px 0px ${rgba(theme.palette.common.black, 0.2)}`,
    display: 'flex',
    justifyContent: 'center',
    height: '60vh',
    minWidth: '432px',
    width: '60vw',
  },
  title: {
    color: theme.palette.text.primary,
    fontSize: '1.25em',
    fontWeight: 700,
  },
}));
