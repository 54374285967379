"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const styles_1 = require("@material-ui/core/styles");
exports.default = styles_1.makeStyles((theme) => ({
    filledError: {
        backgroundColor: '#E5024B',
        color: theme.palette.common.white,
    },
    filledSuccess: {
        backgroundColor: '#1DC18D',
        color: theme.palette.common.white,
    },
}));
