"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.queryToSearch = void 0;
function encode(s) {
    return encodeURIComponent(s);
}
function toSnake(s) {
    return s.replace(/[A-Z]/g, (c) => `_${c.toLowerCase()}`);
}
function queryToSearch(queryObj) {
    return `?${Object.entries(queryObj)
        .map(([key, value]) => `${toSnake(encode(key))}=${encode(value)}`)
        .join('&')}`;
}
exports.queryToSearch = queryToSearch;
