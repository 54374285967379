"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePrevious = void 0;
const react_1 = require("react");
function usePrevious(state) {
    const ref = react_1.useRef();
    react_1.useEffect(() => {
        ref.current = state;
    });
    return ref.current;
}
exports.usePrevious = usePrevious;
