import React from 'react';
import firebase from 'firebase/app';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';

import { Button, CompanyLogo } from '@zeals/shared-components';

import useStyles from './styles';

type GoogleLoginButtonProps = {
  loading?: boolean;
  method?: 'popup' | 'redirect';
  onCancel: () => void;
  onClick: () => void;
  onLogin: (user: firebase.User) => void;
};

const GoogleLoginButton: React.FC<GoogleLoginButtonProps> = ({
  loading = false,
  method = 'popup',
  onCancel,
  onClick,
  onLogin,
}: GoogleLoginButtonProps) => {
  const classes = useStyles();
  const { t: translate } = useTranslation();

  return (
    <Button
      className={classes.button}
      color="default"
      id="login-with-google"
      loading={loading}
      onClick={() => {
        onClick();
        Promise.resolve().then(async () => {
          try {
            const provider = new firebase.auth.GoogleAuthProvider();
            if (method === 'popup') {
              await firebase.auth().signInWithPopup(provider);
            } else {
              // TODO: this may not work, need to verity
              await firebase.auth().signInWithRedirect(provider);
            }
            onLogin(firebase.auth().currentUser);
          } catch (err) {
            // err is an object
            // TODO: check the error code for redirect
            if (err && err.code && err.code === 'auth/popup-closed-by-user') {
              // closed by user, do not throw error
              // reset state
              onCancel();
              return;
            }
            throw err;
          }
        });
      }}
    >
      <div className={classes.buttonTextContainer}>
        <CompanyLogo className={classes.googleLogo} company="google" />
        <Typography className={classes.buttonText} variant="body2">
          {translate('component.googleLoginButton.text')}
        </Typography>
      </div>
    </Button>
  );
};

export default GoogleLoginButton;
