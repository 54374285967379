"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTwilioVideo = void 0;
const react_1 = require("react");
const twilio_video_1 = require("twilio-video");
const useLocalTracks_1 = require("./useLocalTracks");
const containsTrack = (room, name) => {
    const publishedTracks = [...room.localParticipant.tracks.values()];
    return publishedTracks.some((track) => track.trackName === name);
};
const useRemoteParticipants = (room) => {
    const [remoteParticipants, setRemoteParticipants] = react_1.useState([]);
    react_1.useEffect(() => {
        if (!room) {
            return;
        }
        setRemoteParticipants((participants) => [
            ...participants,
            ...room.participants.values(),
        ]);
        room.on('participantConnected', (participant) => {
            setRemoteParticipants((participants) => [...participants, participant]);
        });
        room.on('participantDisconnected', (participant) => {
            setRemoteParticipants((participants) => participants.filter((p) => p.identity !== participant.identity));
        });
    }, [room]);
    // sanity check for remote participants in case the subscrition doesn't work
    react_1.useEffect(() => {
        if (!room || remoteParticipants.length > 0) {
            return () => { };
        }
        const sanityCheck = setInterval(() => {
            const newParticipants = [...room.participants.values()];
            if (newParticipants.length > 0) {
                setRemoteParticipants((existiingParticipants) => {
                    return [
                        ...existiingParticipants,
                        ...newParticipants.filter((participant) => existiingParticipants.every((p) => p.identity !== participant.identity)),
                    ];
                });
            }
        }, 500);
        return () => {
            clearInterval(sanityCheck);
        };
    }, [room, remoteParticipants]);
    return remoteParticipants;
};
const useTwilioLocalTracks = (room, options) => {
    var _a;
    // workaround to refer room in nested object
    const roomRef = react_1.useRef(room);
    react_1.useEffect(() => {
        if (room) {
            roomRef.current = room;
        }
        return () => {
            roomRef.current = null;
        };
    }, [room]);
    const optionsInject = (opts) => {
        return Object.assign(Object.assign({}, opts), { onStart: (track) => {
                var _a;
                if (roomRef.current && !containsTrack(roomRef.current, track.name)) {
                    /**
                     * @privateRemark
                     * @see {@link https://www.twilio.com/docs/video/tutorials/using-track-priority-api}
                     * @see {@link @zeals/admin/config/environments/develop#developConfig.twilioConnectOptions}
                     * We current have no way to tell whether the track here is the PiP thumbnail or not.
                     * Thus no `standard` priority would be set here.
                     * TODO: It is probably possible to do it elsewhere with the published track, though.
                     */
                    roomRef.current.localParticipant.publishTrack(track, {
                        priority: options.screen.enabled ? 'high' : 'low',
                    });
                }
                (_a = opts === null || opts === void 0 ? void 0 : opts.onStart) === null || _a === void 0 ? void 0 : _a.call(opts, track);
            }, onStop: (track) => {
                var _a;
                if (roomRef.current) {
                    // trackUnpublished only trigger on remote partcipant
                    // simulate the behavior for manually published the event for local
                    if (containsTrack(roomRef.current, track.name)) {
                        const currentTrack = [
                            ...roomRef.current.localParticipant.tracks.values(),
                        ].find((t) => t.trackName === track.name);
                        roomRef.current.localParticipant.emit('trackUnpublished', currentTrack);
                        roomRef.current.localParticipant.unpublishTrack(track);
                    }
                }
                (_a = opts === null || opts === void 0 ? void 0 : opts.onStop) === null || _a === void 0 ? void 0 : _a.call(opts, track);
            } });
    };
    const localTracks = useLocalTracks_1.useLocalTracks(Object.assign(Object.assign({}, options), { video: Object.assign(Object.assign({}, options.video), { options: optionsInject(options.video.options) }), audio: Object.assign(Object.assign({}, options.audio), { options: optionsInject(options.audio.options) }), screen: Object.assign(Object.assign({}, options.screen), { options: optionsInject(options.screen.options) }), file: Object.assign(Object.assign({}, options.file), { options: Object.assign({ ref: null }, optionsInject((_a = options.file) === null || _a === void 0 ? void 0 : _a.options)) }) }));
    return localTracks;
};
exports.useTwilioVideo = ({ token, name, localTracksOptions, connectOptions, }) => {
    const [room, setRoom] = react_1.useState();
    const [error, setError] = react_1.useState();
    const remoteParticipants = useRemoteParticipants(room);
    const localTracks = useTwilioLocalTracks(room, localTracksOptions);
    react_1.useEffect(() => {
        if (!token || !name) {
            return () => { };
        }
        Promise.resolve().then(async () => {
            if (room) {
                return;
            }
            try {
                const newRoom = await twilio_video_1.connect(token, connectOptions);
                setRoom(newRoom);
            }
            catch (err) {
                console.error(err);
                setError(err);
            }
        });
        return () => {
            if (room) {
                room.disconnect();
            }
        };
    }, [token, room, name]);
    react_1.useEffect(() => {
        if (!room)
            return;
        const publishedTracks = [...room.localParticipant.tracks.values()].map((track) => track.trackName);
        const unpublishedTracks = localTracks.filter((track) => !publishedTracks.includes(track.name));
        if (unpublishedTracks.length > 0) {
            room.localParticipant.publishTracks(unpublishedTracks);
        }
    }, [room, localTracks]);
    return {
        room,
        remoteParticipants,
        error,
    };
};
