import { action, observable, runInAction } from 'mobx';

import { RoomInstance } from '@zeals/shared-types/build/matching';
import { EnduserInstance } from '@zeals/shared-types/build/enduser';
import { CreateRoomRequest } from '@zeals/shared-types/build/api/matching';

import { RootStore } from './rootStore';
import adminApi from '../services/api/adminApi';

class MatchingStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable fetching = false;

  @observable updating = false;

  @observable room: RoomInstance = null;

  @observable enduser: EnduserInstance = null;

  @action closeRoom = async (id: string): Promise<void> => {
    if (this.updating) return;

    this.updating = true;

    try {
      const response = await adminApi.closeRoom(id);
      if (response.error) {
        const { message } = response.error;
        throw new Error(message);
      }
    } finally {
      runInAction(() => {
        this.updating = false;
      });
    }
  };

  @action createRoom = async (
    request: CreateRoomRequest
  ): Promise<RoomInstance> => {
    // TODO: probably query Simplybook first to ensure the operator's preconditions?

    // add the person to the conversation
    const response = await adminApi.createRoom(request);
    if (response.error) {
      const { message } = response.error;
      throw new Error(message);
    }

    if (!response.data.room) {
      throw new Error();
    }

    return response.data.room;
  };

  @action getRoom = async (id: string): Promise<void> => {
    if (this.fetching) return;

    this.fetching = true;

    try {
      const response = await adminApi.getRoom(id);
      if (response.error) {
        const { message } = response.error;
        throw new Error(message);
      }

      if (!response.data.enduser || !response.data.room) {
        throw new Error();
      }

      const { enduser, room } = response.data;
      this.enduser = enduser;
      this.room = room;
    } finally {
      runInAction(() => {
        this.fetching = false;
      });
    }
  };

  @action listMyRooms = async (): Promise<RoomInstance[]> => {
    const response = await adminApi.listMyRooms();
    if (response.error) {
      const { message } = response.error;
      throw new Error(message);
    }

    if (!response.data.rooms) {
      throw new Error();
    }

    return response.data.rooms;
  };

  @action setRoom = (room: RoomInstance): void => {
    this.room = room;
  };
}

export default MatchingStore;
