import React from 'react';

import useStyles from './styles';

type HiddenVideoProps = {
  enabled: boolean;
  videoRef: React.MutableRefObject<HTMLVideoElement>;
  src: string;
};

const HiddenVideo: React.FC<HiddenVideoProps> = ({
  videoRef,
  src,
  enabled,
}: HiddenVideoProps) => {
  const classes = useStyles();

  if (!enabled) {
    return null;
  }

  return (
    <video
      ref={videoRef}
      src={src}
      muted
      className={classes.video}
      loop
      preload="auto"
    />
  );
};

export default HiddenVideo;
