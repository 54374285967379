import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dialog as Modal } from '@material-ui/core';
import {
  CompanyLogo,
  Dialog,
  ErrorMessage,
  LandingSVG,
} from '@zeals/shared-components';
import useStyles from './styles';

type EnduserExitModalProps = {
  open: boolean;
  onClose: () => void;
};

const EnduserExitModal: React.FC<EnduserExitModalProps> = ({
  open = false,
  onClose,
}: EnduserExitModalProps) => {
  const classes = useStyles();
  const { t: translate } = useTranslation();

  return (
    <Modal
      classes={{
        paper: classes.paper,
      }}
      open={open}
    >
      <Dialog
        header={{
          logo: <CompanyLogo company="his" reserveFill scale={1} />,
        }}
        body={{
          children: (
            <ErrorMessage
              svg={<LandingSVG />}
              title={translate('common.errors.enduserExit.title')}
            />
          ),
        }}
        buttons={{
          children: (
            <div className={classes.buttonsContainer}>
              <Button
                className={classes.button}
                color="primary"
                fullWidth
                onClick={() => onClose()}
                variant="outlined"
              >
                {translate('common.buttons.close')}
              </Button>
            </div>
          ),
        }}
      />
    </Modal>
  );
};

export default EnduserExitModal;
