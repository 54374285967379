import { makeStyles } from '@material-ui/core/styles';
import { rgba } from '@zeals/shared-utils';

type DeviceSettingPanelProps = {
  isModal: boolean;
};

export default makeStyles((theme) => ({
  formControl: {
    background: theme.palette.common.white,
    border: '1px solid rgba(0, 82, 180, 0.5)',
    boxSizing: 'border-box',
    borderRadius: '10px',
    marginRight: '-28px',
    maxWidth: '280px',
    minWidth: '80px',
    width: '100%',
    '&>select': {
      height: '40px',
    },
  },
  label: (props: DeviceSettingPanelProps) => ({
    color: props.isModal ? '#0D47A1' : theme.palette.grey[300],
    fontSize: '0.75rem',
    fontWeight: 700,
    margin: '5px',
  }),
  panelContainer: {
    display: 'grid',
    gridTemplateColumns: 'minmax(0, 180px) minmax(0, 1fr)',
    gridTemplateRows: 'minmax(0, 40px) minmax(0, 1fr)',
    height: '100%',
    width: '100%',
    '& >.settingsTitle': {
      gridColumn: 1,
      gridRow: 1,
      '& >.font': {
        color: rgba(theme.palette.primary.main, 0.5),
        fontSize: '1.25rem',
        fontWeight: '700',
      },
    },
    '& >.videoRow': {
      gridColumn: '1 / span 2',
      gridRow: 2,
      '& >.videoRowContainer': {
        display: 'grid',
        gridTemplateColumns: 'minmax(0, 180px) minmax(0, 1fr)',
        height: '95%',
        '& >.videoContainer': {
          alignSelf: 'stretch',
          display: 'grid',
          gridColumn: 1,
          gridTemplateColumns: '1fr 1fr 1fr',
          gridTemplateRows: 'minmax(0, 1fr) minmax(0, 20px)',
          position: 'relative',
          '& >.micIcon': {
            alignSelf: 'end',
            gridColumn: 2,
            gridRow: 2,
            justifySelf: 'center',
            zIndex: 1,
          },
          '& >.videoLoading': {
            alignSelf: 'center',
          },
        },
        '& >.videoSettings': {
          alignSelf: 'center',
          gridColumn: 2,
          marginLeft: '10px',
        },
      },
    },
  },
  panelContainerAlternative: {
    alignContent: 'flex-start',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    height: '100%',
    justifyContent: 'flex-start',
    '& >.titleRow': {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      maxHeight: '92px',
      width: '100%',
      '& >.font': {
        color: rgba(theme.palette.primary.main, 0.5),
        fontSize: '1.25rem',
        fontWeight: '700',
      },
    },
    '& >.videoRow': {
      display: 'grid',
      gridTemplateColumns: 'auto minmax(0, 40px) 1fr',
      height: '210px',
      margin: 0,
      position: 'relative',
      width: '100%',
      '& >.videoContainer': {
        display: 'flex',
        gridColumn: 1,
        justifyContent: 'center',
        position: 'relative',
        width: '120px',
        '& >.micIcon': {
          alignSelf: 'flex-end',
          gridColumn: 2,
          gridRow: 2,
          zIndex: 1,
        },
      },
      '& >.videoSettingsContainer': {
        gridColumn: 3,
        width: '100%',
      },
    },
  },
  videoTag: {
    borderRadius: '10px',
    height: '100%',
    right: 0,
    top: 0,
    transform: 'rotateY(180deg)',
    '-webkit-transform': 'rotateY(180deg)' /* Safari and Chrome */,
    '-moz-transform': 'rotateY(180deg)' /* Firefox */,
    width: '100%',
  },
}));
