import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextField, Typography } from '@material-ui/core';

import { EnduserInstance } from '@zeals/shared-types/build/enduser';

import useStyles from './styles';

type EnduserProfileProps = {
  enduser: EnduserInstance;
  memo: string;
  disableMemoEdit?: boolean;
  onMemoChange?: (memo: string) => void;
};

const EnduserProfile: React.FC<EnduserProfileProps> = ({
  enduser,
  memo,
  disableMemoEdit = false,
  onMemoChange,
}: EnduserProfileProps) => {
  const classes = useStyles();
  const { t: translate } = useTranslation();

  return (
    <div className={classes.userInfoContainer}>
      <div className="infoContainer">
        <div className="personalInfoRow">
          <div className="name">
            <Typography className={classes.label}>
              {translate('component.enduserProfile.userInfo.name')}
            </Typography>
            <Typography className={classes.infoText}>{enduser.name}</Typography>
          </div>
          <div className="furigana">
            <Typography className={classes.label}>
              {translate('component.enduserProfile.userInfo.furigana')}
            </Typography>
            <Typography className={classes.infoText}>
              {enduser.metadata.furigana}
            </Typography>
          </div>
          <div className="phone">
            <Typography className={classes.label}>
              {translate('component.enduserProfile.userInfo.phone')}
            </Typography>
            <Typography className={classes.infoText}>
              {enduser.phone}
            </Typography>
          </div>
          <div className="origin">
            <Typography className={classes.label}>
              {translate('component.enduserProfile.userInfo.livingArea')}
            </Typography>
            <Typography className={classes.infoText}>
              {translate(`common.regions.${enduser.metadata.region}`)}
            </Typography>
          </div>
          <div className="destination">
            <Typography className={classes.label}>
              {translate('component.enduserProfile.userInfo.destination')}
            </Typography>
            <Typography className={classes.infoText}>
              {translate(`common.regions.${enduser.metadata.destination}`)}
            </Typography>
          </div>
        </div>
      </div>
      <div className="memoContainer">
        <div className="memoText">
          <TextField
            className="memoTextField"
            disabled={disableMemoEdit}
            value={memo}
            InputProps={{
              classes: {
                input: classes.memoTextFieldInput,
                notchedOutline: classes.memoTextFieldInputOutline,
                root: classes.memoTextFieldInputRoot,
              },
            }}
            label={translate('component.enduserProfile.memo.title')}
            multiline
            onChange={(e) => {
              onMemoChange?.(e.target.value);
            }}
            placeholder={translate('component.enduserProfile.memo.placeholder')}
            rows={12}
            variant="outlined"
          />
        </div>
      </div>
    </div>
  );
};

export default EnduserProfile;
