"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const styles_1 = require("@material-ui/core/styles");
exports.default = styles_1.makeStyles((theme) => {
    return {
        icon: {
            color: theme.palette.error.main,
        },
    };
});
