import { makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles<Theme>((theme) => {
  return {
    button: {
      alignItems: 'center',
      backgroundColor: theme.palette.common.white,
      borderRadius: '5px',
      display: 'flex',
      height: '56px',
      fontWeight: 600,
      justifyContent: 'center',
      maxHeight: '56px',
      maxWidth: '360px',
      width: '360px',
      '&:hover': {
        backgroundColor: theme.palette.grey[100],
      },
    },
    buttonText: {
      color: theme.palette.grey[600],
      fontSize: '0.75em',
      fontWeight: 'bold',
      textTransform: 'capitalize',
    },
    buttonTextContainer: {
      display: 'flex',
    },
    googleLogo: {
      marginRight: theme.spacing(4),
    },
  };
});
