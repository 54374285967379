"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const GoogleTagManager = ({ containerId, children, }) => {
    // https://developers.google.com/tag-manager/quickstart
    const script = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','${containerId}')`;
    const noscript = `<iframe src="https://www.googletagmanager.com/ns.html?id=${containerId}"
  height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
    react_1.useEffect(() => {
        const scriptElement = document.createElement('script');
        scriptElement.innerHTML = script;
        document.head.append(document.head.childNodes[0], scriptElement);
        const nocriptElement = document.createElement('noscript');
        nocriptElement.innerHTML = noscript;
        document.body.append(document.body.childNodes[0], nocriptElement);
    }, []);
    return children;
};
exports.default = GoogleTagManager;
